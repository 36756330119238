import React, { useEffect } from "react";
import { StyledPayment} from "./style";
// import CardLogo from "../../assests/img/card-logo.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { processAuthorizeNetPay } from "../../redux/paymentSlice";
import { getTotalAmount } from "../../redux/applyNowSlice";
import { decryptVal } from "../../utility/utility";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";

const Payment = () => {
  // const [show, setShow] = useState(false);
  // const target = useRef(null);
  const dispatch = useDispatch();
  const orderId = useSelector((state) => state.applyNow.orderIDs);
  const applyNowFormData = useSelector(
    (state) => state.applyNow.applyNowFormData
  );
  const amount = useSelector((state) => state.applyNow.amount);
  const customerDetails = useSelector(
    (state) => state.applyNow.customerDetails
  );

  const OrderId = decryptVal(localStorage.getItem("orderId"));

  const initialValues = {
    cardNumbercardNumber: "",
    expMonth: "",
    expYear: "",
    cvvCode: "",
    orderId: OrderId,
    email: customerDetails?.Email,
    amount: amount,
    firstName: "",
    lastName: "",
  };

  const paymentSchema = yup.object({
    cardNumbercardNumber: yup
      .string()
      .max(19)
      .min(19)
      .required("Please enter your Card Number."),
    expMonth: yup
      .string()
      .max(12)
      .min(1)
      .required("Please enter Card Expiration Month."),
    expYear: yup
      .string()
      .max(4)
      .min(4)
      .required("Please enter Card Expiration Year."),
    cvvCode: yup.string().max(3).min(3).required("Please enter your CVV Code."),
  });

  useEffect(() => {
    let data = {
      orderId: OrderId,
    };
    dispatch(getTotalAmount(data));
    document.title = "Secure Payment";
  }, [OrderId]);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: paymentSchema,
    onSubmit: (values) => {
      dispatch(processAuthorizeNetPay(values));
    },
  });

  return (
    <StyledPayment>
      <section class="in-banner">
        <img src={Image} alt="" />
        <div class="text-ban">
          <h1>Secure Payment</h1>
        </div>
      </section>
      <section class="application">
        <div class="container">
          <div class="row">
            <form class="payment_form" id="payment-form" method="post">
              <div class="col-md-6 col-md-offset-3">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <div class="row text-center">
                      <h3 class="panel-heading">Payment Details</h3>
                    </div>
                  </div>

                  <div class="panel-body">
                    <div class="form-group">
                      <label>Card Number</label>
                      <input
                        type="text"
                        class="form-control cardnumber"
                        id="card-number"
                        data-stripe="number"
                        required="required"
                        maxlength="19"
                        minlength="19"
                        placeholder="Exp: 1234 5555 4444 3333"
                        onkeyup="numberMobile(event);"
                        onkeypress="return onlyNumberKey(event)"
                      />
                      <input type="hidden" id="carderror" value="0" />
                    </div>
                    <br />
                    <div class="form-group">
                      <label>Expiry Month</label>
                      <input
                        type="text"
                        class="form-control expmonth"
                        data-stripe="exp_month"
                        required="required"
                        maxlength="2"
                        minlength="1"
                        placeholder="MM"
                      />
                    </div>
                    <br />
                    <div class="form-group">
                      <label>Expiry Year</label>
                      <input
                        type="text"
                        class="form-control expyear"
                        data-stripe="exp_year"
                        required="required"
                        maxlength="4"
                        minlength="4"
                        placeholder="YYYY"
                      />
                    </div>
                    <br />
                    <div class="form-group">
                      <label>CVC</label>
                      <input
                        type="text"
                        class="form-control cvv"
                        data-stripe="cvc"
                        required="required"
                        maxlength="3"
                        minlength="3"
                        placeholder="111"
                      />
                    </div>
                    <div class="form-group">
                      <button
                        type="submit"
                        class="btn btn-success btn-md btn-block"
                      >
                        Make Payment
                      </button>
                      <span class="payment-errors"></span>
                    </div>

                    <div class="form-row order-summary">
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <div class="applicant_name">
                            <strong>Order ID: </strong> SL-ETIAS-100060
                          </div>
                          <div class="applicant_name">
                            <strong>Email: </strong>{" "}
                          </div>
                          <div class="member_name">
                            <strong>Amount: </strong>$ 19.99
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </StyledPayment>
  );
};

export default Payment;
