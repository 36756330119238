import React, { useEffect } from "react";
import { StyledWrapper } from "./style";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";

const RefundPolicy = () => {
  useEffect(() => {
    document.title = "Refund Policy";
  }, []);
  return (
    <StyledWrapper>
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>Refund Policy</h1>
        </div>
      </section>
      <section className="privacy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-bot">
                <h2 className="heading">Refund Policy</h2>
              </div>
              <div className="">
                <p style={{ "text-align": "justify" }}>
                  <h3> Background </h3>
                  For inquiries relating to your application: in the first
                  instance please send us an email to:{" "}
                  <a href="mailto:inquiries@philippines-evisa.com">
                    inquiries@philippines-evisa.com
                  </a>
                  <br />
                  <br />
                  <h3>
                    <b>Our Trading Address:</b>
                  </h3>
                  <br />
                  philippines-evisa.com, <br />
                  1800 Pembrook Dr. <br />
                  Suite 300, <br />
                  Orlando, FL 32810 <br />
                  United States
                  <br />
                  <br />
                  <h3> Refund Policy </h3>
                  <h3> 1. REFUND & COMPLAINTS </h3>
                  Users of our services are protected by United States of
                  America & International law. The service we offer is a digital
                  personalized service, by proceeding to submit an eTA
                  application you agree to waive your cancellation rights.
                  <h3> 2. REFUNDS & COMPLAINTS PROCEDURE </h3>
                  All refund requests and formal complaints must be made via
                  email to{" "}
                  <a href="mailto:inquiries@philippines-evisa.com">
                    inquiries@philippines-evisa.com
                  </a>
                  <h3> 3. TIME FRAME </h3>
                  We aim to process successfully submitted eTA applications
                  within 24 hours. However we are not liable or responsible for
                  delays caused in processing of the eTA application. We do not
                  advise submitting an eTA application via
                  www.philippines-evisa.com within 48 hours of travel.
                  <h3> 4. SERVICE COSTS AND FEES </h3>
                  By accepting these terms and conditions and after successful
                  payment, you are instructing philippines-evisa.com data
                  processing team to process your eTA application on your
                  behalf. The $70.00 USD fee is charged per applicant and
                  includes the following benefits; Confirmation Of Application,
                  Admission and Error Checks On Application, Reimbursement For
                  Declined eTA Applications, 24/7 Email Support and Advice. We
                  as a merchant shall be under no liability whatsoever in
                  respect of any loss or damage arising directly or indirectly
                  out of the decline of authorization for any Transaction, on
                  Account of the Cardholder having exceeded the preset limit
                  mutually agreed by us with our acquiring bank from time to
                  time.
                  <h3> 5. NO WAIVER </h3>
                  In the event that any party to these Terms and Conditions
                  fails to exercise any right or remedy contained herein, this
                  shall not be construed as a waiver of that right or remedy.
                  <h3> 6. NOTICES </h3>
                  All notices / communications shall be given to us either by
                  post to our Premises or by email to{" "}
                  <a href="mailto:inquiries@philippines-evisa.com">
                    inquiries@philippines-evisa.com
                  </a>
                  . Such notice will be deemed received 3 days after posting if
                  sent by first class post, the day of sending if the email is
                  received in full on a business day and on the next business
                  day if the email is sent on a weekend or public holiday.
                  www.philippines-evisa.com is a trading name of
                  philippines-evisa.com is a company registered in United States
                  of America. The registered office of philippines-evisa.com is
                  1800 Pembrook Dr. Suite 300, Orlando, FL 32810 United States
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StyledWrapper>
  );
};

export default RefundPolicy;
