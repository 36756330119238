import React from "react";
import { Link } from "react-router-dom";
import { StyledSlide } from "./style";

const Slide = (props) => {
  const { img, title, content } = props;
  return (
    <StyledSlide>
      <div className="item">
        <img className="d-block w-100" src={img} alt="First slide" />
        <div className="caption d-md-block">
          <div className="display">{title} </div>
          <div className="content">{content}</div>
          <Link to="/apply-now" className="btn-theme">
            Apply Now
          </Link>
        </div>
      </div>
    </StyledSlide>
  );
};

export default Slide;
