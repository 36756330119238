import React, { useEffect } from "react";
import { StyledWrapper } from "./style";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = "Terms And Conditions";
  }, []);
  return (
    <StyledWrapper>
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>Terms And Conditions</h1>
        </div>
      </section>
      <section className="privacy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-bot">
                <h2 className="heading" style={{ color: "#0042b3" }}>
                  Terms And Conditions
                </h2>
              </div>
              <p style={{ "text-align": "justify", "line-height": "40px" }}></p>
              <h3 className="large"> Background </h3>
              For inquiries relating to your application: in the first instance
              please send us an email to:{" "}
              <a href="mailto:inquiries@philippines-evisa.com">
                inquiries@philippines-evisa.com
              </a>
              <h3 className="large">Our Trading Address: </h3>
              philippines-evisa.com, <br />
              1800 Pembrook Dr. <br />
              Suite 300, Orlando <br />
              FL 32810
              <h3 className="large"> TERMS AND CONDITIONS </h3>
              This agreement applies as between you, the User of this Web Site
              and philippines-evisa.com, the owner(s) of this Web Site. Your
              agreement to comply with and be bound by these terms and
              conditions is deemed to occur upon your first use of the Web Site.
              If you do not agree to be bound by these terms and conditions, you
              should stop using the Web Site immediately.
              <a href="/">www.philippines-evisa.com</a> is a trading name of
              philippines-evisa.com is a company registered in United States.
              The registered office of philippines-evisa.com, 1800 Pembrook Dr.
              Suite 300, Orlando, FL 32810
              <h3 className="large"> 1. DEFINITIONS AND INTERPRETATION </h3>
              In this Agreement the following terms shall have the following
              meanings: “Content” means any text, graphics, images, audio,
              video, software, data compilations and any other form of
              information capable of being stored in a computer that appears on
              or forms part of this Web Site; “Premises” means ,
              philippines-evisa.com, 1800 Pembrook Dr. Suite 300, Orlando, FL
              32810 “Service” means collectively any online facilities, tools,
              services or information that philippines-evisa.com makes available
              through the Web Site either now or in the future; “System” means
              any online communications infrastructure that
              philippines-evisa.com makes available through the Web Site either
              now or in the future. This includes, but is not limited to,
              web-based email, message boards, live chat facilities and email
              links; “User” / “Users” means any third party that accesses the
              Web Site and is not employed by philippines-evisa.com and acting
              in the course of their employment; and “Web Site” means the
              website that you are currently using (
              <a href="/">www.philippines-evisa.com</a>) and any sub-domains of
              this site (e.g. subdomain.philippines-evisa.com ) unless expressly
              excluded by their own terms and conditions.
              <h3 className="large"> 2. INTELLECTUAL PROPERTY </h3>
              2.1 All Content included on the Web Site, unless uploaded by
              Users, including, but not limited to, text, graphics, logos,
              icons, images, sound clips, video clips, data compilations, page
              layout, underlying code and software is the property of{" "}
              <a href="/">www.philippines-evisa.com</a>, our affiliates or other
              relevant third parties. By continuing to use the Web Site you
              acknowledge that such material is protected by applicable US and
              International intellectual property and other relevant laws. 2.2
              Subject to sub-clause 2.3 you may not reproduce, copy, distribute,
              store or in any other fashion re-use material from the Web Site
              unless otherwise indicated on the Web Site or unless given express
              written permission to do so by philippines-evisa.com 2.3 Material
              from the Web Site may be re-used without written permission where
              any of the exceptions in line with United States of America law
              <h3 className="large"> 3. LINKS TO OTHER WEB SITES </h3>
              This Web Site may contain links to other sites. Unless expressly
              stated, these sites are not under the control of
              philippines-evisa.com or that of our affiliates. We assume no
              responsibility for the content of such web sites and disclaim
              liability for any and all forms of loss or damage arising out of
              the use of them. The inclusion of a link to another site on this
              Web Site does not imply any endorsement of the sites themselves or
              of those in control of them.
              <h3 className="large"> 4. LINKS TO THIS WEB SITE </h3>
              Those wishing to place a link to this Web Site on other sites may
              do so only to the home page of the site{" "}
              <a href="/">www.philippines-evisa.com</a> without prior
              permission. Deep linking (i.e. links to specific pages within the
              site) requires the express permission of philippines-evisa.com. To
              find out more please contact us by email at{" "}
              <a href="mailto:inquiries@philippines-evisa.com">
                inquiries@philippines-evisa.com
              </a>
              <h3 className="large"> 5. PRIVACY </h3>
              Use of the Web Site is also governed by our Privacy Policy which
              is incorporated into these terms and conditions by this reference.
              To view the Privacy Policy, please click on the link above.
              <h3 className="large"> 6. DISCLAIMERS </h3>
              6.1 philippines-evisa.com makes no warranty or representation that
              the Web Site will meet your requirements, that it will be of
              satisfactory quality, that it will be for a particular purpose,
              that it will not infringe the rights of third parties, that it
              will be compatible with all systems, that it will be secure and
              that all information provided will be accurate. We make no
              guarantee of any specific results from the use of our Service. 6.2
              No part of this Web Site is intended to constitute advice and the
              Content of this Web Site should not be relied upon when making any
              decisions or taking any action of any kind.
              <h3 className="large"> 7. AVAILABILITY OF THE WEB SITE </h3>
              The Service is provided “as is” and on an “as available” basis. We
              give no warranty that the Service will be free of defects and / or
              faults. To the maximum extent permitted by The Law of United
              States of America, we provide no warranties (express or implied)
              of fitness for a particular purpose, accuracy of information,
              compatibility and satisfactory quality.{" "}
              <a href="/">www.philippines-evisa.com</a> accepts no liability for
              any disruption or non-availability of the Web Site resulting from
              external causes including, but not limited to, ISP equipment
              failure, host equipment failure, communications network failure,
              power failure, natural events, acts of war or legal restrictions
              and censorship.
              <h3 className="large"> 8. LIMITATION OF LIABILITY </h3>
              8.1 To the maximum extent permitted by United States of America
              law, philippines-evisa.com accepts no liability for any direct or
              indirect loss or damage, foreseeable or otherwise, including any
              indirect, consequential, special or exemplary damages arising from
              the use of the Web Site or any information contained therein.
              Users should be aware that they use the Web Site and its Content
              at their own risk. 8.2 Nothing in these terms and conditions
              excludes or restricts philippines-evisa.com liability for death or
              personal injury resulting from any negligence or fraud on the part
              of philippines-evisa.com. 8.3 Every effort has been made to ensure
              that these terms and conditions adhere strictly with the relevant
              provisions of the all applicable ISA law. However, in the event
              that any of these terms are found to be unlawful, invalid or
              otherwise unenforceable, that term is to be deemed severed from
              these terms and conditions and shall not affect the validity and
              enforceability of the remaining terms and conditions. This term
              shall apply only within jurisdictions where a particular term is
              illegal.
              <h3 className="large"> 9. NO WAIVER </h3>
              In the event that any party to these Terms and Conditions fails to
              exercise any right or remedy contained herein, this shall not be
              construed as a waiver of that right or remedy.
              <h3 className="large"> 10. PREVIOUS TERMS AND CONDITIONS </h3>
              In the event of any conflict between these Terms and Conditions
              and any prior versions thereof, the provisions of these Terms and
              Conditions shall prevail unless it is expressly stated otherwise.
              <h3 className="large"> 11. NOTICES </h3>
              All notices / communications shall be given to us either by post
              to our Premises (see address above) or by email to{" "}
              <a href="mailto:inquiries@philippines-evisa.com">
                inquiries@philippines-evisa.com
              </a>
              . Such notice will be deemed received 3 days after posting if sent
              by first class post, the day of sending if the email is received
              in full on a business day and on the next business day if the
              email is sent on a weekend or public holiday.
              <h3 className="large"> 12. LAW AND JURISDICTION </h3>
              These terms and conditions and the relationship between you and
              philippines-evisa.com shall be governed by and construed in
              accordance with the Law of United States of America and
              philippines-evisa.com and you agree to submit to the exclusive
              jurisdiction of the Courts of United States of America.
              <h3 className="large"> 13. TIME FRAME </h3>
              We aim to process successfully submitted applications within 24
              hours. However we are not liable or responsible for delays caused
              in processing of the application. We do not advise submitting an
              application for a eTA Application via{" "}
              <a href="/">www.philippines-evisa.com</a> within 48 hours of
              travel.
              <h3 className="large"> 14. SERVICE COSTS AND FEES </h3>
              By accepting these terms and conditions and after successful
              payment, you are instructing philippines-evisa.com data processing
              team to process your eTA application on your behalf. The $70.00
              USD fee is charged per applicant and includes the following
              benefits; Confirmation Of Application, Admission and Error Checks
              On Application, Reimbursement For Declined eTA Application, 24/7
              Email Support and Advice. We as a merchant shall be under no
              liability whatsoever in respect of any loss or damage arising
              directly or indirectly out of the decline of authorization for any
              Transaction, on Account of the Cardholder having exceeded the
              preset limit mutually agreed by us with our acquiring bank from
              time to time.
              <h3 className="large">
                {" "}
                15. REFUND &amp; COMPLAINTSY15. REFUND &amp; COMPLAINTS{" "}
              </h3>
              Users of our services are protected by United States of America
              &amp; International law. The service we offer is a digital
              personalized service, by proceeding to submit an application you
              agree to waive your cancellation rights.
              <h3 className="large"> 16. DISCLAIMER </h3>
              This Website contains information concerning
              philippines-evisa.com, Its Services, Products, Materials and other
              information that may be for our customers, employees, shareholders
              and other stake holders and the general public. The said
              information is provided on an “as is” basis without any warranty
              of any kind. philippines-evisa.com disclaims all representations
              and warranties, express or implied, as to the accuracy, currency,
              or completeness of the contents of this website and use of its
              contents for any particular purpose, to the maximum extent
              permitted by applicable law. philippines-evisa.com shall not be
              liable for any kind of direct, indirect, consequential,
              Incidental, special or punitive losses or damages (even if
              philippines-evisa.com has been advised of the possibility of such
              damages) including any damage to your computer or any other device
              arising out of access to, use of or in ability to use this
              website, to the fullest extent as permitted by law.
              philippines-evisa.com does not warranty use of the contents of
              this website for any particular purpose. philippines-evisa.com
              shall not be liable philippines-evisa.com shall not be liable for
              any kind of direct, indirect, consequential, Incidental, special
              or punitive losses or damage arising out of access to, use of or
              in ability to use this website. You should carefully read all
              information provided before using any product purchased on this
              website. Any links on this website to websites operated by third
              parties are provided for your convenience only.
              philippines-evisa.com is not responsible for the content and
              performance of these websites or for your transactions with them
              and our inclusion of links to such websites does not imply any
              endorsement of the material on such sites.
              <h3 className="large"> REFUNDS &amp; COMPLAINTS PROCEDURE </h3>
              All refund requests and formal complaints must be made via email
              to{" "}
              <a href="mailto:inquiries@philippines-evisa.com">
                inquiries@philippines-evisa.com
              </a>
              <p></p>
            </div>
          </div>
        </div>
      </section>
    </StyledWrapper>
  );
};

export default TermsAndConditions;
