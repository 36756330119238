import React from "react";
import { StyledParentSection, StyledChildSection, StyledHome } from "./style";
import img1 from "../../assests/img/slide1.png";
import img2 from "../../assests/img/slide2.png";
import img3 from "../../assests/img/slide3.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slide from "../../Components/Slide/Slide";
// import KoreaLogo from "../../assests/img/footer-logo-korea.png";

const Home = () => {
  const content1 =
    "We are committed to providing the most quick and efficient eTA processing service available online.";

  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    nav: true,
    // navText:['<','>']
    // title:true,
    // content:true
  };
  return (
    <StyledHome>
      <section className="banner">
        <OwlCarousel className="owl-main  owl-theme" {...options}>
          <Slide
            img={img1}
            title="GET YOUR PHILIPPINES ETA"
            content={content1}
            loop
          />
          <Slide
            img={img2}
            title="GET YOUR PHILIPPINES ETA"
            content={content1}
            loop
          />
          <Slide
            img={img3}
            title="GET YOUR PHILIPPINES ETA"
            content={content1}
          />
        </OwlCarousel>
      </section>
      <StyledParentSection>
        <section className="why-u1">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3
                  align="center"
                  //  style="font-weight:bold; color:#cf0220;"
                >
                  What is the Philippines Electronic Travel Authorization?
                </h3>
                <p>
                  The Philippines eTA is an electronic travel authorization that permits eligible citizens to travel for short stays.
                </p>

                <ul>
                  <li>
                  The Philippines electronic visa simplifies the process of obtaining the necessary
                  authorization to travel to the country and eliminates the need to apply for a visa for
                  the Philippines from an embassy or consulate.                  </li>

                  <li>
                  The Philippines eVisa is a multiple entry travel authorization that permits multiple
                  entries to the Philippines during its validity, for a total stay of 30 days in the country.                  </li>

                  <li>
                  The eTA Philippines is valid for 1 month from the moment of approval.
                  Eligible citizens are able to apply through the simple Philippines eVisa application,
                  which only takes a few minutes to complete. It is necessary to fill in the Philippines
                  visa application form online with personal, passport, and travel information, as well
                  as to indicate the purpose of the trip, in order to receive an approved visa for the
                  Philippines online via email.
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </section>

        <StyledChildSection>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 align="center">Who We are</h3>
                <p>
                We are committed to providing the best and most efficient eTA Application
                processing service available online. Our expert travel consultants will guide you
                through the whole process and carefully review all the information submitted, in order
                to help you avoid mistakes and save you the time and stress of trying to navigate the
                complexities of submitting a eTA application on your own. Moreover, in case of
                application rejection, the entire cost of the application is refunded, included the
                Government processing fees.
                </p>
                <p>
                philippines-evisa.com are a private company and not affiliated to the South Korea
                government or any government body. We act as agents for your eTA application. We
                abide by the Data protection Act and all data you provide will be solely used for your
                application.
                </p>
              </div>
            </div>
          </div>
        </StyledChildSection>
      </StyledParentSection>
    </StyledHome>
  );
};

export default Home;
