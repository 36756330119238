import { configureStore } from "@reduxjs/toolkit";
import applyNowReducer from "./applyNowSlice";
import authReducer from "./authSlice";
import orderReducer from "./orderSlice";
import gateWayReducer from "./gateWaySlice";
import manageTeamReducer from "./manageTeamSlice";
import remarkReducer from "./remarkSlice";
import paymentReducer from "./paymentSlice";
import frontReducer  from "./frontSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    applyNow: applyNowReducer,
    order: orderReducer,
    manageTeam: manageTeamReducer,
    gateway: gateWayReducer,
    remark: remarkReducer,
    payment: paymentReducer,
    front: frontReducer,
  },
});
