import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { StyledHeader } from "./style";

const Header = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <StyledHeader>
      <div id="topbar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2"></div>

                <div className="col-md-6"></div>
                <div className="col-md-4 google_translation_section">
                    <div
                      id="google_translate_element"
                    ></div>

                  <span className="address content">
                    <Link to="mailto:inquiries@philippines-evisa.com">
                      inquiries@philippines-evisa.com
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
