import styled, { keyframes } from "styled-components";
import image from "../../assests/img/home-bg.jpg";
// import { merge, slideInLeft, slideInUp } from "react-animations";
//const tadaFlip = merge(tada, flip);

// const slideInLeftAnimation = keyframes`${slideInLeft}`;
// const slideInUpAnimation = keyframes`${slideInUp}`;

export const StyledHome = styled.div`
  .banner {
    position: relative;
  }

  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    min-height: 400px;


    @media (max-width: 991px) {
      min-height: 400px;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  .owl-prev {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    margin-left: -20px;
    display: block !important;
    border: 0px solid black;

    @media (max-width: 767px) {
      left: unset;
      margin: 0px -47px 0;
    }
  }

  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    background-color: #cf0a21;
    border: none;
    padding: 0 !important;
    // font: inherit;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    // background-color: #ffcd00;
    border-radius: 0;
    font-size: 20px;
    position: absolute;
    top: 50%;
    outline: none;
  }
  .banner .owl-carousel .owl-nav .owl-next {
    right: 30px;

    @media (max-width: 767px) {
      top: unset;
      bottom: 39px;
    }
  }

  .owl-next {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    right: -25px;
    display: block !important;
    border: 0px solid black;

    @media (max-width: 767px) {
      right: unset;
      margin: 0 3px 0;
    }
  }

  .banner .owl-theme .owl-nav {
    margin-top: 0px;
  }

  .banner .owl-carousel .owl-nav button.owl-prev {
    left: 30px;

    @media (max-width: 767px) {
      top: unset;
      bottom: 39px;
    }
  }

  .banner .owl-theme .owl-dots {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
  }
  .owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 0.2s ease;
    border-radius: 30px;
  }

  .banner .owl-theme .owl-dots .owl-dot span {
    width: 40px;
    height: 3px;
    background: #fff;
    border-radius: 0;
  }
  .banner .owl-theme .owl-dots .owl-dot.active span {
    background-color: #ffcd00;
  }

  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #869791;
  }
`;

export const StyledParentSection = styled.div`
  background: url(${image});
  background-size: cover;

  h3 {
    font-size: 20px;
    font-weight: bold;
    color: #cf0220;
  }

  .h1,
  .h2,
  .h3,
  h1,
  h2,
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    margin: 0 0 10px;
  }

  ul {
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 25px;
  }
`;

export const StyledChildSection = styled.div`
  border: 0;
  padding: 30px 0 15px;
  text-align: center;
  color: #333;
  position: relative;
`;
