import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { thankyouPageInformation } from "../../redux/paymentSlice";
import { StyledThankYou } from "./style";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";

const ThankYou = () => {
  const dispatch = useDispatch();
  const orderId = useSelector((state) => state.applyNow.orderIDs);
  const Id = localStorage.getItem("orderId");

  useEffect(() => {
    let data = {
      Id: Id,
    };
    dispatch(thankyouPageInformation(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("orderId");
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        toast.error(`${err.message}`, {
          className: "toast-message",
        });
      });

    document.title = "Thank You";
  }, []);

  return (
    <StyledThankYou>
      <section className="in-banner">
        <img src={Image} alt="image" />
        <div className="text-ban">
          <h1>Transaction Status</h1>
        </div>
      </section>
      <section className="application">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="thanks_wrapper">
                <div className="thanks_inner">
                  <img src="images/check-circle.gif" alt="check"/>
                  <h3 className="thank_msg">
                    <strong>Payment has been received.</strong>
                  </h3>
                  <p className="paragraph">
                    This is the Order ID for your payment :{" "}
                    <strong>order_id</strong>{" "}
                  </p>
                  <br />
                  <p>
                    Thank you for your payment. Your eTA application will now be
                    processed within 24 hours. If you would like to place
                    another order, please click on the button below:
                  </p>

                  <div className="center_btn">
                    <div>
                      <Link to="apply.php" className="btn-theme">
                        Click Here
                      </Link>
                    </div>

                    <p>
                      If you need any assistance please visit our website or
                      email{" "}
                      <Link to="mailto:inquiries@srilanka-eta.com">
                        inquiries@srilanka-eta.com
                      </Link>
                    </p>

                    <p>
                      Regards,
                      <br />
                      <br />
                      Processing Department
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StyledThankYou>
  );
};

export default ThankYou;
