import React from "react";
import { StyledChild, StyledContainer, StyledDiv } from "./style";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";

// import { FaPlus } from "react-icons/fa";

const FAQ = () => {
  return (
    <StyledContainer size="large">
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>FAQ</h1>
        </div>
      </section>
      <section className="faq">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-bot">
                <h2 className="heading">General Questions</h2>
              </div>

              <p>View frequently asked questions and answers below.</p>
              <StyledChild>
                <StyledDiv>
                  <MDBAccordion initialActive={1}>
                    <MDBAccordionItem
                      collapseId={1}
                      headerTitle="Who is eligible for the Philippine eTA?"
                      className="fa-plus:before"
                    >
                      {" "}
                      <p>
                        ALL incoming international travelers including Filipino
                        citizens and foreigners, adults and children, must
                        secure an eTravel QR code online 72 HOURS or THREE DAYS
                        before your arrival.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={2}
                      headerTitle="
                      Is an eVisa the same as a visa?
                      "
                    >
                      <p>
                        A visa is a legal document that allows you to enter a
                        specific country for a specific duration of time. An
                        E-Visa means that people are able to apply for a visa
                        online. They can sign the application form and attach
                        all the documents in virtual form and then submit the
                        application.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={3}
                      headerTitle="
                      What information and documents do i need for the application
                      "
                    >
                      <p>
                        Requirements for the visa include filling out an
                        application with your personal information like your
                        passport information, travel and lodging plans, and
                        return flight information.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={4}
                      headerTitle="
                      How many entries am I permitted with a Philippines eVisa?
                       "
                    >
                      <p>
                        The Philippines eVisa is a multiple entry travel
                        authorization that allows multiple entries to the
                        Philippines during its validity.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={5}
                      headerTitle="
                      How many days can I stay in the Philippines using an online visa?
                        "
                    >
                      <p>
                        Holders of the Philippines online visa are able to stay
                        in the Philippines for a total of 30 days.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={6}
                      headerTitle="
                      Do children need a eVisa for the Philippines?"
                    >
                      <p>
                        Yes, all minors from eligible countries are required to
                        have an eVisa for the Philippines to enter the country.
                      </p>
                      <ul>
                        <li>
                          Parents or legal guardians are able to complete an
                          application on behalf of their kids.
                        </li>
                        <li>
                          Children over the age of 15 are able to complete their
                          own applications.
                        </li>
                      </ul>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={7}
                      headerTitle="
                      Can I register a few days before the flight (before I travel)?"
                    >
                      <p>
                        Yes. You may only register within 3 days (or 72 hours)
                        prior to your arrival in the Philippines. Travelers are
                        required to present their proof of valid eTravel
                        registration prior to flight boarding. It is important
                        that your recent declaration of health condition is
                        within 72 hours before arrival.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem
                      collapseId={8}
                      headerTitle="
                      What do I need to do after I register?
         
                          "
                    >
                      <p>
                        Take a screenshot or Download of your personal QR Code.
                        You will be required to show this QR code to an airline
                        representative before being allowed to board your
                        flight. Upon arrival in the Philippines, you must
                        present this QR code to the BOQ Quarantine officers for
                        verification.
                      </p>
                    </MDBAccordionItem>

                    <MDBAccordionItem
                      collapseId={9}
                      headerTitle="
                      Do I need to print my e-Visa?
           
                            "
                    >
                      <p>
                        Passport control officers at ports of entry can verify
                        your e-Visa on their system. However, you are advised to
                        keep your e-Visa with you either as a soft copy (tablet
                        PC, smart phone, etc.) or as a hard copy in case of any
                        failure on their system.
                      </p>
                    </MDBAccordionItem>
                  </MDBAccordion>
                </StyledDiv>
              </StyledChild>
            </div>
          </div>
        </div>
      </section>
    </StyledContainer>
  );
};

export default FAQ;
