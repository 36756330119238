import React, { useEffect } from "react";
import { StyledSection } from "./style";
import OrderIcon from "../../assests/img/order_icon.png";
import ReadingIcon from "../../assests/img/reading_icon.png";
import MobileIcon from "../../assests/img/mobile.png";
import ChatIcon from "../../assests/img/chat.png";
import HelpLineIcon from "../../assests/img/helpline_icon.png";
import SecurityIcon from "../../assests/img/security_icon.png";
import PhoneIcon from "../../assests/img/phone.png";
import SupportIcon from "../../assests/img/support_icon.png";
import TranslationIcon from "../../assests/img/translation_travel_icon.png";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";

const AdditionalBenefits = () => {
  useEffect(() => {
    document.title = "Additional Benefits";
  }, []);
  return (
    <StyledSection>
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>Additional Benefits</h1>
        </div>
      </section>
      <section className="add-table">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row img-container">
                <div className="col-lg-4 mt-4 mt-lg-4 img-wrapper">
                  <img src={OrderIcon} alt="OrderIcon" />
                  <p> All applications are manually checked </p>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-4 img-wrapper">
                  <img src={ReadingIcon} alt="ReadingIcon" />
                  <p> Instant email confirmation </p>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-4 img-wrapper">
                  <img src={MobileIcon} alt="MobileIcon" />
                  <p> Instant SMS confirmation </p>
                </div>
              </div>

              <div className="row img-container">
                <div className="col-lg-4 mt-4 mt-lg-4 img-wrapper">
                  <img src={ChatIcon} alt="ChatIcon" />

                  <p> live chat support </p>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-4 img-wrapper">
                  <img src={HelpLineIcon} alt="HelpLineIcon" />
                  <p> Customer support helpline </p>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-4 img-wrapper">
                  <img src={SecurityIcon} alt="SecurityIcon" />

                  <p>
                    {" "}
                    All data is fully secure and not passed to any third party.{" "}
                  </p>
                </div>
              </div>

              <div className="row img-container">
                <div className="col-lg-4 mt-4 mt-lg-4 img-wrapper">
                  <img src={PhoneIcon} alt="PhoneIcon" />

                  <p> Our website is mobile and tablet friendly. </p>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-4 img-wrapper">
                  <img src={SupportIcon} alt="SupportIcon" />
                  <p> Telephone application service. </p>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-4 img-wrapper">
                  <img src={TranslationIcon} alt="TranslationIcon" />

                  <p> Our Website can be changed to any Language. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StyledSection>
  );
};

export default AdditionalBenefits;
