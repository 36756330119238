import ApiService from "./ApiService";

export default class applyNowService {
  static getCountryListing = () =>
    ApiService.get("/common/get-country-listing");

  static getEmbassyListing = (countryId) =>
    ApiService.get(`/common/get-embassy-listing/${countryId}`);

  static getCountryInfoListing = () =>
    ApiService.get("/common/get-country-info-listing");

  static customerApplicationForm = (data) =>
    ApiService.post("/front/customer-application-details", data);

  static customerEtaApplicationForm = (data) =>
    ApiService.post("/eta-front/customer-application-details", data);

  static customerEtaApplicationPreview = (data) =>
    ApiService.post("/eta-front/customer-application-preview", data);

  static customerApplicationPreview = (data) =>
    ApiService.post("/eta-front/customer-application-preview", data);

  static getTotalAmount = (data) =>
    ApiService.post("/front/get-total-amount", data);
}
