import styled from "styled-components";

export const StyledParentFooter = styled.div`
  #footer {
    padding: 10px;
    width: 100%;
    background: #000;
    max-width: 100%;
    color: #ffffff;
  }
`;


export const StyledFooter = styled.div`
  background-color: #cf0a21;
  padding: 26px 0;
  color: #fff;
  font-size: 14px;
  text-align: center;

  ul li a {
    color: #fff;
    font-size: 14px;
}

  & img {
    width: 240px;
  }

 h4 {
    margin-top:0;
    text-align: left;
  }

  ul {
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 25px;
  }

   ul li {
    display: block;
    text-align: left;
    padding: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
}
`;
