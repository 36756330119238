import styled from "styled-components";

export const StyledHomeFooter = styled.div`
  padding: 27px 0px;

  .cookie-disclaimer {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 999999999999;
    background: #000;
    color: #fff;
    font-size: 14px;
    padding: 8px 10px;
    text-align: center;
  }

  & p {
    color: #fff;
    margin: 0;
  }

  & a {
    text-decoration: none;
  }

  .btn-theme {
    background-color: #cf0a21;
    border: 1px solid #fff;
    color: #fff;
  }
`;

export const StyledNewFooter = styled.div`
  #footer {
    padding: 10px;
    width: 100%;
    background: #aa0100;
    max-width: 100%;
    color: #ffffff;
  }
`;
