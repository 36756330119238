import React, { useEffect } from "react";
import { StyledContainer, StyledGatewayBlock } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getPaymentGateways,
  updatePaymentGateway,
} from "../../../redux/gateWaySlice";
import { encryptVal } from "../../../utility/utility";

const initialValues = {
  gatewayName: "",
};

const GateWaySchema = yup.object({
  gatewayName: yup.string().required("Please select gateway."),
});

const Gateway = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentGateways = useSelector(
    (state) => state?.gateway?.paymentGateways
  );

  useEffect(() => {
    dispatch(getPaymentGateways())
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/");
        }
      });
    document.title = "Payment Setting | Philippine E-Visa";
  }, [dispatch, navigate]);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setInitialValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: GateWaySchema,
    onSubmit: (values) => {
      dispatch(updatePaymentGateway(encryptVal(values.gatewayName)))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
          } else {
            toast.error(`${res.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });

  paymentGateways?.map((item, i) => {
    if (item?.is_active == "1") {
      initialValues.gatewayName = item?.id;
    }
  });

  return (
    <StyledContainer>
      <div className="row">
        <div className="col-md-12">
          <StyledGatewayBlock>
            <h2>Change Gateway</h2>
            <div className="table-block table-responsive">
              <form onSubmit={handleSubmit}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "30%" }}>Gateway</td>
                      <td style={{ width: "70%" }}>
                        <select
                          className="form-control gateway_name font-normal"
                          name="gatewayName"
                          value={values.gatewayName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Select Gateway</option>
                          {paymentGateways?.map((item, i) => {
                            return (
                              <option value={item?.id} key={i}>
                                {item?.gateway_name}
                              </option>
                            );
                          })}
                        </select>
                        <p>{errors.gatewayName}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button type="submit" className="btn blue-btn">
                          Active Gateway
                        </button>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </StyledGatewayBlock>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Gateway;
