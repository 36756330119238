import styled from "styled-components";

export const StyledThankYou = styled.div`
.in-banner {
  position: relative;
}
.in-banner img {
width: 100%;

@media (max-width: 767px) {
    min-height: 144px;
    object-fit: cover;
}
}
.in-banner h1 {
font-size: 40px;
font-weight: bold;
color: #fff;
margin: 0;
border-bottom: 2px solid #fff;
}

.text-ban {
position: absolute;
top: 50%;
left: 19%;
transform: translateY(-50%);

@media (max-width: 767px){
    left: 0%;
    padding: 0 15px;
}

@media (max-width: 1440px){
    left: 6%;
}
}

.about-us {
padding: 96px 0;
}

.border-bot {
position: relative;
display: none;
}

.border-bot h2 {
margin: 0 0 70px;
}
.privacy {
padding: 25px 0;
line-height: 25px !important;
text-align: justify;
}

.large {
color: #0042b3;
font-weight: bold;
}

& h3 {
font-size: 20px;
color: #0042b3;
font-weight: bold;
}

h3 {
margin-top: 20px;
margin-bottom: 10px;
}

& a {
color: #337ab7;
text-decoration: none;
}
  & h2 {
    text-align: center;
  }


  .application {
  padding: 90px 0;
min-height:450px;
& img{
  width:4%;

}
}

& a{
  padding:10px;
  text-align: center;
  margin-bottom: 15px;

}

.paragraph {
  text-align: center;
  font-weight: 600;
  font-size: 20px;

}




& form {
  width: 100%;
  background: #fff;

  & label {
    font-weight: bold;
    font-size: 14px;

    .needed {
      font-weight: bold;
      font-style: italic;
      color: #d3080c;
    }

  }

 }

 .textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #0042b3;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 0;
    box-shadow: none;
    height: 50px !important;
    border-color: #0042b3 !important;
    // background-color: #f5f6f7 !important;
    box-shadow: 0 0 5pt 0.5pt #adc1db !important;
}


   
  }

  & input {
    // padding: 20px 15px !important;
    }


    & h3 {
    text-align:left;
    }

    .form-group {
      margin-bottom: 15px;
  }
  .btn-fom {
      margin: 38px 0 0;
  }

    .btn-theme {
      background-color: #cf0a21;
      border: 1px solid #fff;
  }

  .btn-theme {
    background-color: #cf0a21;
    color: #fff;
    border: 1px solid #ffcd00;
    display: inline-block;
    padding: 5px;

}
.btn-fom a, .btn-fom button {
  padding: 12px 56px;
  font-size: 18px;
}

.thanks_wrapper{
  width: 88%;
  padding: 15px;
  margin: 0 auto;
}
.thanks_inner{
  width: 100%;
}
.thanks_inner img {
  text-align: center;
  margin: 0 auto;
  width: 10% !important;
  display: block;
}
.thank_msg{
  font-weight: 600;
  text-align: center;
}
.center_btn{text-align: center;}
`;
