import styled from "styled-components";

export const StyledContainer = styled.div`
  margin-top: 70px;
  & a {
    color: #00abc9;
    outline: 0;
    text-decoration: none;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }

  .blue-heading {
    font-weight: 600;
  }

  & h2 {
    text-align: center;
  }

  .container {
    margin-top: 30px;
  }
`;
