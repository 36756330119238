import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { StyledOrderBlock, StyledBottomBlock } from "./style";
// import { FaDownload } from "react-icons/fa";
import rightArrow from "../../assests/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetailsByOrderId,
  resendMail,
  uploadVisaDocument,
} from "../../redux/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [show, setShow] = useState(false);

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const isLoading = useSelector((state) => state?.order?.loading);

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });

  const [emailId, setEmailId] = useState(OrderDetails?.email);
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
    fileUpload: yup.mixed().required("File is required"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
    }

    document.title = "Orders Details | Philippine E-Visa";
  }, [dispatch, orderId]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.email &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(OrderDetails?.email);
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: "",
    fileUpload: "",
  };

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email: OrderDetails?.email,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("orderId", values.orderId);
      data.append("email", values.email);
      data.append("uploadVisaDoc", values.fileUpload);
      // dispatch(uploadVisaDocument(data))
      //   .unwrap()
      //   .then((res) => {
      //     resetForm();
      //     if (res.status === 1) {
      //       toast.success(`${res.message}`, {
      //         className: "toast-message",
      //       });
      //       dispatch(getOrderDetailsByOrderId(orderId));
      //     }
      //   });
    },
  });

  const handleResendMail = (e) => {
    e.preventDefault();
    const data = {
      orderId: OrderDetails?.order_id,
      email: emailId,
    };
    // dispatch(resendMail(data))
    //   .unwrap()
    //   .then((res) => {
    //     if (res.status === 1) {
    //       toast.success(`${res.message}`, {
    //         className: "toast-message",
    //       });
    //       dispatch(getOrderDetailsByOrderId(orderId));
    //     }
    //   });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "15%" }}>Order ID</th>
                      <th style={{ width: "35%" }}>Download</th>
                      <th style={{ width: "10%" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">{item?.order_id}</td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.length !== 0
                                    ? `${process.env.REACT_APP_BASE_URL}/${
                                        process.env.REACT_APP_PUBLIC_UPLOAD_PATH
                                      }docs/${
                                        item?.uploadDoc == null
                                          ? OrderDetails?.uploadDoc[0]?.file1
                                          : item?.uploadDoc.file1
                                      }`
                                    : null
                                }
                                className="blue-btn"
                                target="_blank"
                                // download
                                rel="noreferrer"
                              >
                                Download File
                              </a>
                            )}
                          </td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control input-class"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={handleResendMail}
                    >
                      Resend Mail{" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>PDF</td>
                      <td>
                        <label className="file-upload">
                          <input
                            type="file"
                            className=""
                            id="fileUpload"
                            name="fileUpload"
                            accept=".pdf,image/*"
                            onChange={(e) =>
                              setFieldValue(
                                "fileUpload",
                                e.currentTarget.files[0]
                              )
                            }
                            onBlur={handleBlur}
                          />
                          <span>Choose File</span>
                        </label>
                        <span className="slcted-file"></span>
                        <p>{errors.fileUpload}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {OrderDetails?.uploadDoc?.length !== 0 && (
                          <a
                            href={
                              OrderDetails?.length !== 0
                                ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                                : null
                            }
                            className="blue-btn"
                            target="_blank"
                            // download
                            rel="noreferrer"
                          >
                            Download File
                          </a>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  disabled={isLoading}
                >
                  Submit{" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.order_id && (
                    <tr>
                      <td>Order ID:</td>
                      <td>{OrderDetails?.order_id}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Visa Details:</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.visa_type && (
                    <tr>
                      <td>Visa Type</td>
                      <td>{OrderDetails?.visa_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.entry_type && (
                    <tr>
                      <td>Entry Type</td>
                      <td>{OrderDetails?.entry_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.country_of_application && (
                    <tr>
                      <td>Country Of Application</td>
                      <td>{OrderDetails?.country_of_application}</td>
                    </tr>
                  )}

                  {OrderDetails?.application_type && (
                    <tr>
                      <td>Application Type</td>
                      <td>{OrderDetails?.application_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.embassy && (
                    <tr>
                      <td>Embassy/Consulate</td>
                      <td>{OrderDetails?.embassy}</td>
                    </tr>
                  )}

                  {OrderDetails?.confirm_email && (
                    <tr>
                      <td>Confirm confirm_email</td>
                      <td>{OrderDetails?.email}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Passport Details:</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.passport_number && (
                    <tr>
                      <td>Passport/Travel Document no.</td>
                      <td>{OrderDetails?.passport_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.issue_date && (
                    <tr>
                      <td>Date Issue</td>
                      <td>{OrderDetails?.issue_date}</td>
                    </tr>
                  )}

                  {OrderDetails?.valid_date && (
                    <tr>
                      <td>Valid Until</td>
                      <td>{OrderDetails?.valid_date}</td>
                    </tr>
                  )}

                  {OrderDetails?.issued_by && (
                    <tr>
                      <td>Issued By</td>
                      <td>{OrderDetails?.issued_by}</td>
                    </tr>
                  )}

                  {OrderDetails?.port_of_entry && (
                    <tr>
                      <td>Port of Entry (City)</td>
                      <td>{OrderDetails?.port_of_entry}</td>
                    </tr>
                  )}

                  {OrderDetails?.length_of_stay && (
                    <tr>
                      <td>Length of Stay (Days)</td>

                      <td>{OrderDetails?.length_of_stay}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Personal Details:</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.name && (
                    <tr>
                      <td>Name</td>
                      <td>{OrderDetails?.name}</td>
                    </tr>
                  )}

                  {OrderDetails?.surname && (
                    <tr>
                      <td>Surname</td>
                      <td>{OrderDetails?.surname}</td>
                    </tr>
                  )}

                  {OrderDetails?.sex && (
                    <tr>
                      <td>Sex</td>
                      <td>{OrderDetails?.sex}</td>
                    </tr>
                  )}

                  {OrderDetails?.date_of_birth && (
                    <tr>
                      <td>Date of Birth</td>
                      <td>{OrderDetails?.date_of_birth}</td>
                    </tr>
                  )}

                  {OrderDetails?.age && (
                    <tr>
                      <td>Age</td>
                      <td>{OrderDetails?.age}</td>
                    </tr>
                  )}

                  {OrderDetails?.place_of_birth && (
                    <tr>
                      <td>Place of Birth</td>
                      <td>{OrderDetails?.place_of_birth}</td>
                    </tr>
                  )}

                  {OrderDetails?.contact_number && (
                    <tr>
                      <td>Contact Number</td>
                      <td>{OrderDetails?.contact_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.father_name && (
                    <tr>
                      <td>Father's Name</td>
                      <td>{OrderDetails?.father_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.mother_name && (
                    <tr>
                      <td>Mother's Name</td>
                      <td>{OrderDetails?.mother_name}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Applicant Address</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.house_number && (
                    <tr>
                      <td>Room/House No.</td>
                      <td>{OrderDetails?.house_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.street && (
                    <tr>
                      <td>Street</td>
                      <td>{OrderDetails?.street}</td>
                    </tr>
                  )}

                  {OrderDetails?.town && (
                    <tr>
                      <td>Town</td>
                      <td>{OrderDetails?.town}</td>
                    </tr>
                  )}

                  {OrderDetails?.province && (
                    <tr>
                      <td>Province</td>
                      <td>{OrderDetails?.province}</td>
                    </tr>
                  )}

                  {OrderDetails?.postal_code && (
                    <tr>
                      <td>Postal Code</td>
                      <td>{OrderDetails?.postal_code}</td>
                    </tr>
                  )}

                  {OrderDetails?.occupation && (
                    <tr>
                      <td>Occupation</td>
                      <td>{OrderDetails?.occupation}</td>
                    </tr>
                  )}

                  {OrderDetails?.civil_status && (
                    <tr>
                      <td>Civil Status</td>
                      <td>{OrderDetails?.civil_status}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Download History</h2>
            <div className="table-block table-responsive">
              <table width="100%">
                <thead>
                  <tr>
                    <th width="5%">#</th>
                    <th width="10%">Order ID</th>
                    <th width="22%">Date & Time</th>
                    <th width="23%">Downloaded File</th>
                    <th width="20%">IP Address</th>
                    <th width="20%">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderDetails?.downloadHistory ? (
                    OrderDetails?.downloadHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}{" "}
                            EST
                          </td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.uploadDoc !== undefined
                                    ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : null
                                }
                                className="blue-btn"
                                target="_blank"
                                // download
                                rel="noreferrer"
                              >
                                Download File
                              </a>
                            )}
                          </td>
                          <td>{item?.ip}</td>
                          <td>{item?.timezone}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <form className="form-inline">
                <div className="form-group">
                  <label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control input-class"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    id="download_btn"
                    onClick={handleResendMail}
                    disabled={isLoading}
                  >
                    Sent Mail{" "}
                    <span>
                      <img src={rightArrow} alt="rightArrow" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </StyledOrderBlock>
          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== "undefined" &&
                  OrderDetails.remark ? (
                    OrderDetails.remark?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.us_date).format("MM/DD/YYYY")}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                            selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>

        <StyledBottomBlock>
          <button
            type="button"
            className="blue-btn"
            data-toggle="modal"
            data-target="#refundModal"
            onClick={() => setShow(true)}
          >
            View Email Content{" "}
          </button>

          <MyModal
            show={show}
            close={setShow}
            size={"lg"}
            modalHeadTitle={"Email Content"}
            showFooter={true}
            primaryButtonName={"Close"}
            secondaryButtonName={"Send Whatsapp Message"}
            OrderDetails={OrderDetails}
          >
            <EmailContent />
          </MyModal>
        </StyledBottomBlock>
      </div>
    </>
  );
};

export default ViewOrders;
