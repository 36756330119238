import React, { useEffect, useState } from "react";
import { StyledPreview, StyledError, StyledPreviewForm } from "./style";
import Signature from "signature_pad";
import { FaEraser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerApplicationPreview } from "../../redux/applyNowSlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { customerApplicationPreviewSubmit } from "../../redux/paymentSlice";
import { decryptVal } from "../../utility/utility";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";
// import MyModal from "../../Components/Modal/Modal";
// import PreviewForm from "../PreviewForm";

const Preview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signaturePad, setSignaturePad] = useState();
  // const [show, setShow] = useState(false);
  const applicantDetails = useSelector(
    (state) => state.applyNow.applicantDetails
  );
  const [processTime, setProcessTime] = useState("Regular Processing");

  let Id = decryptVal(localStorage.getItem("Id"));

  const readyPad = () => {
    let wrapper = document.getElementById("signature-pad");
    let canvas = wrapper?.querySelector("canvas");
    canvas.getContext("2d").scale(1, 1);
    let tempSignaturePad = new Signature(canvas, {
      backgroundColor: "rgb(255, 255, 255)",
    });
    setSignaturePad(tempSignaturePad);
  };

  const handleClear = () => {
    signaturePad.clear();
  };
  const initialValues = {
    processTime: processTime,
    signature: signaturePad,
    orderId: Id,
  };

  const PreviewSchema = yup.object({
    processTime: yup.string().required("Please select your processig time."),
    // terms: yup.string().required("This field is required."),
  });

  let signatureError;

  useEffect(() => {
    if (typeof Id !== undefined) {
      dispatch(customerApplicationPreview({ Id: Id }))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
          } else {
            toast.error(`${res.message}`, {
              className: "toast-message",
            });
          }
        });
    }
    readyPad();
    document.title = "Review Application";
  }, [dispatch, Id]);

  const {
    errors,
    values,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: PreviewSchema,
    onSubmit: (values) => {
      signatureError = signaturePad.isEmpty();
      if (signaturePad.isEmpty() === false) {
        let data = {
          Id: Id,
          processingMode: values?.processTime,
          signature: signaturePad?.toDataURL(),
        };
        dispatch(customerApplicationPreviewSubmit(data))
          .unwrap()
          .then((res) => {
            if (res.status === 1) {
              toast.success(`${res.message}`, {
                className: "toast-message",
              });
              navigate("/payment-net");
            } else {
              toast.error(`${res.message}`, {
                className: "toast-message",
              });
            }
          });
      }
    },
  });

  return (
    <StyledPreview>
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>Application Form</h1>
          <p>General questions and answers are found on this page.</p>
        </div>
      </section>
      <section className="application">
        <div className="container">
          <table>
            {applicantDetails?.map((item, index) => {
              return (
                <tbody>
                  <tr>
                    <th colSpan="2">Order Details</th>
                  </tr>
                  <tr>
                    <th colSpan="2">Order ID :{item?.id}</th>
                  </tr>
                  <tr>
                    <th colSpan="2">Application</th>
                  </tr>
                  <tr>
                    <th colSpan="2">Visa Details</th>
                  </tr>
                  <tr>
                    <th>Visa Type</th>
                    <td>{item?.visa_type}</td>
                  </tr>
                  <tr>
                    <th>Entry Type</th>
                    <td>{item?.entry_type}</td>
                  </tr>
                  <tr>
                    <th>Country Of Application</th>
                    <td>{item?.country_of_application}</td>
                  </tr>
                  <tr>
                    <th>Application Type</th>
                    <td>{item?.application_type}</td>
                  </tr>
                  <tr>
                    <th>Embassy/Consulate</th>
                    <td>{item?.embassy}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{item?.email}</td>
                  </tr>
                  <tr>
                    <th>Confirm Email</th>
                    <td>{item?.confirm_email}</td>
                  </tr>

                  <tr>
                    <th colSpan="2">Personal Information</th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{item?.name}</td>
                  </tr>
                  <tr>
                    <th>Surname</th>
                    <td>{item?.surname}</td>
                  </tr>
                  <tr>
                    <th>Sex</th>
                    <td>{item?.sex}</td>
                  </tr>

                  <tr>
                    <th>Date of Birth</th>
                    <td>{item?.date_of_birth}</td>
                  </tr>

                  <tr>
                    <th>Age</th>
                    <td>{item?.age}</td>
                  </tr>

                  <tr>
                    <th>Place of Birth</th>
                    <td>{item?.place_of_birth}</td>
                  </tr>

                  <tr>
                    <th>Country of citizenship</th>
                    <td>{item?.country_of_Citizenship}</td>
                  </tr>

                  <tr>
                    <th>Contact Number</th>
                    <td>{item?.contact_number}</td>
                  </tr>

                  <tr>
                    <th>Father's Name</th>
                    <td>{item?.father_name}</td>
                  </tr>

                  <tr>
                    <th>Mother's Name</th>
                    <td>{item?.mother_name}</td>
                  </tr>

                  <tr>
                    <th colSpan="2">Applicant Address</th>
                  </tr>

                  <tr>
                    <th>Room/House No.</th>
                    <td>{item?.house_number}</td>
                  </tr>

                  <tr>
                    <th>Street</th>
                    <td>{item?.street}</td>
                  </tr>

                  <tr>
                    <th>Town</th>
                    <td>{item?.town}</td>
                  </tr>

                  <tr>
                    <th>Province</th>
                    <td>{item?.province}</td>
                  </tr>

                  <tr>
                    <th>Postal Code</th>
                    <td>{item?.postal_code}</td>
                  </tr>

                  <tr>
                    <th>Occupation</th>
                    <td>{item?.occupation}</td>
                  </tr>

                  <tr>
                    <th>Civil Status</th>
                    <td>{item?.civil_status}</td>
                  </tr>

                  <tr>
                    <th colSpan="2">Passport Details</th>
                  </tr>

                  <tr>
                    <th>Passport/Travel Document no.</th>
                    <td>{item?.passport_number}</td>
                  </tr>

                  <tr>
                    <th>Date Issue</th>
                    <td>{item?.issue_date}</td>
                  </tr>

                  <tr>
                    <th>Valid Until</th>
                    <td>{item?.valid_date}</td>
                  </tr>

                  <tr>
                    <th>Issued By</th>
                    <td>{item?.issued_by}</td>
                  </tr>

                  <tr>
                    <th>Port of Entry (City)</th>
                    <td>{item?.port_of_entry}</td>
                  </tr>

                  <tr>
                    <th>Length of Stay (Days)</th>
                    <td>{item?.length_of_stay}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
          <br />
        </div>
      </section>
      <StyledPreviewForm>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12 mb-25">
              <h4> Processing Time </h4>
              <p>your application will now be processed within 3 days</p>
              <h5>Have you ever visited Korea before? *</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-25">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="processTime"
                  id="inlineRadio1"
                  value={"Regular Processing"}
                  checked={processTime === "Regular Processing"}
                  onChange={(e) => setProcessTime("Regular Processing")}
                  onBlur={handleBlur}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Standard Processing : 1 - 72 hours{" "}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="processTime"
                  id="inlineRadio2"
                  value={"Priority Processing"}
                  checked={processTime === "Priority Processing"}
                  onChange={(e) => setProcessTime("Priority Processing")}
                  onBlur={handleBlur}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Priority Processing : 1 hour + ($19.99)
                </label>
              </div>
              <StyledError>{errors.processTime}</StyledError>
            </div>
          </div>
          <div id="signature-pad">
            <canvas className="signature-canvas"></canvas>
            <div>
              <button
                onClick={handleClear}
                id="clear"
                className="btn btn-warning next"
                type="button"
              >
                <FaEraser /> Clear
              </button>
            </div>
          </div>
          <StyledError>
            {signatureError === true || signaturePad?.isEmpty()
              ? "Please put your signature"
              : null}
          </StyledError>{" "}
          <br />
          <strong class="text-danger">I Agree (Required)</strong>
          <br />
          <br />
          <div className="row row-top">
            <div className="col-lg-6">
              <button type="submit" name="submit" className="btn btn-pay">
                Pay Now<i className="fal fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </form>
      </StyledPreviewForm>
    </StyledPreview>
  );
};

export default Preview;
