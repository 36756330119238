import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../pages/AdminLogin/Login";

const SignupLayout = (props) => (
  <Routes>
    <Route path="/login" element={<Login />} />
  </Routes>
);

export default SignupLayout;
