export const decryptVal = (string) => {
  try {
    string = atob(string);
    if (!isNaN(string)) {
      return string / 2;
    }
    return string;
  } catch (err) {
    return 0;
  }
};

export const encryptVal = (string) => {
  try {
    if (!isNaN(string)) {
      string = parseInt(string) + parseInt(string);
    }
    string = btoa(string);
    return string.replace(/=+$/, "");
  } catch (err) {
    return 0;
  }
};

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export const capitalizeFirstLowercaseRest = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const suffix = [
  "JR",
  "SR",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
];

export const travelPurpose = [
  "Business/Professional",
  "Convention/Conference",
  "Education/Training/Studies",
  "Government/Official Mission",
  "Health/Medical Reason",
  "Holiday/Pleasure/Vacation",
  "Incentive",
  "Others",
  "Religion/Pilgrimage",
  "Visit Friends/Relatives",
  "Work/Employment",
];

export const seaPort = [
  "DAVAO TORIL FISHPORT COMPLEX",
  "Lal-lo Seaport",
  "Manila South Harbor",
  "Port of Bacolod",
  "Port of Bataan (PHBTN)",
  "Port of Batangas (PHBTG)",
  "Port of Bislig",
  "Port of Cagayan de Oro",
  "Port of Calbayog",
  "Port of Cebu (PHCEB)",
  "Port of Currimao (ONRI)",
  "Port of General Santos",
  "Port of Iligan",
  "Port of Iloilo (PHILO)",
  "Port of Laoag",
  "Port of Legazpi",
  "Port of Masinloc",
  "Port of Pangasinan",
  "Port of Puerto Princesa",
  "Port of Sasa, Davao Seaport",
  "Port of Sta Cruz",
  "Port of Surigao",
  "San Fernando International Seaport",
  "San Fernando, Luzon (PHSFE)",
  "Subic Bay (PHSFS)",
  "Subic Bay Yacht Club",
  "Tabacco Seaport",
  "Tacloban Seaport",
  "Zamboanga Port",
];

export const airlineList = [
  "Air Busan",
  "Air China",
  "Air Hongkong",
  "Air Niugini",
  "Air Seoul",
  "AirAsia / Berhad",
  "AirSWIFT",
  "All Nippon Airways",
  "Asiana",
  "Cathay Pacific",
  "Cebu Pacific",
  "China Airlines",
  "China Eastern Flight",
  "China Southern Airlines",
  "Emirates Airlines",
  "Ethiopian Airlines",
  "Etihad Airlines",
  "EVA Air",
  "FEDEX",
  "Fly Gangwon",
  "Gulf Air",
  "Hong Kong Airlines",
  "Hunnu Air",
  "Icelandair",
  "Japan Airlines",
  "Jeju Air",
  "Jetstar Asia",
  "Jetstar Japan",
  "Jin Air",
  "KLM Royal Dutch Airlines",
  "Korean Air",
  "Kuwait Air",
  "LOONG AIR",
  "Malaysia Airlines",
  "OK Air",
  "Oman Air",
  "PAL Express",
  "Pan Pacific Airlines",
  "Philippine Airlines",
  "Private",
  "Qantas Airways",
  "Qatar Airways",
  "Royal Air",
  "Royal Brunei",
  "Saudia",
  "Scoot Flight",
  "Singapore Airlines",
  "Skyjet Airlines",
  "STARLUX Airlines",
  "Thai Airways",
  "Tianjin Air Cargo",
  "Tigerair Taiwan",
  "Turkish Airlines",
  "Tway Airlines",
  "United Airlines",
  "United Parcel Service",
  "VietJet Air",
  "Xiamen",
  "Zipair",
];

export const airportOfDeparture = [
  "Bacolod Airport",
  "Bohol-Panglao International Airport (New Bohol Int'l.)",
  "Cagayan North International Airport",
  "Caticlan Airport (MPH)",
  "Clark International Airport (CRK)",
  "Davao International Airport (DVO)",
  "General Santos Airport",
  "Iloilo International Airport (ILO)",
  "Kalibo International Airport (KLO)",
  "Laoag International Airport (LAO)",
  "Mactan–Cebu International Airport (CEB)",
  "Ninoy Aquino International Airport T1 - (MNL)",
  "Ninoy Aquino International Airport T2 - (MNL)",
  "Ninoy Aquino International Airport T3 - (MNL)",
  "Port of Cagayan de Oro, Mindanao",
  "Port of Davao, Mindanao (PHDVO)",
  "Port of Zamboanga (PHZAM)",
  "Puerto Princesa International Airport (PPS)",
  "Puerto Princesa, Palawan",
  "Subic Bay International Airport (SFS)",
];

export const hotelsList = [
  "(074) 424-8070",
  "(074) 442-9968",
  "1001 NIGHTS CDO CORPORATION",
  "1010 HOTEL",
  "101IOTEL",
  "128 LODGE",
  "1318 RESIDENCES",
  "150 PEAKWAY MOUNTAIN RESORT",
  "1521 HOTEL",
  "153 EXECUTIVE SUITES",
  "168 FELIPA MANSION & APARTELLE",
  "1758 CIUDAD FERNANDINA HOTEL CORPORATION",
  "1758 CIUDAD FERNANDINA HOTEL CORPORATION",
  "181 NORTH PLACE RESIDENCES",
  "1875 RICE HOTEL BOUTIQUE 101",
  "1898 HOTEL COLONIA EN LAS FILIPINAS",
  "1A EXPRESS HOTEL",
  "1ST CITY LODGE",
  "2&2 SCUBA DIVING RESORT CORP.",
  "2024 BAGUIO APARTMENTS REAL ESTATE LEASING",
  "2205 SUITES (EAST ORIENT PROPERTIES HOLDINGS, INC.)",
  "24/7 INN & RESORT",
  "2428 SUITES",
  "2JKLA NATURE HOT SPRING RESORT",
  "3 GEMS PENSION HOUSE",
  "3 J & P OCEAN VIEW COTTAGES",
  "3-A TRAVELLERS INN",
  "357 BORACAY RESORT/MARIA ANA MIGUEL REALTY, INC.",
  "360° MOUNTAIN VIEW RESORT AND DEVELOPMENT CENTER",
  "38 DEGREES POOL RESORT, INC. *",
  "3BU HOSTEL",
  "3G GARDEN HOTEL",
  "3J AND P OCEAN VIEW COTTAGES",
  "3K HOTEL AND RESTAURANT",
  "3R TRAVELLERS INN",
  "3RR HOMESTAY",
  "401K RESORT",
  "456 HOTEL",
  "4J-R FARMVILLE RESORT",
  "5880 ENRIQUEZ RESIDENCES",
  "5J'S CAMPSITE AND HOMESTAY",
  "5R ROOMS FOR RENT",
  "5R'S TRANSIENT HOUSE",
  "67TH HEAVEN HOLIDAY RESORT",
  "6S TRADING CORPORATION",
  "7 MEADOWS INN",
  "7 SEAS BEACH RESORT",
  "717 CESAR PLACE",
  "7STONES BORACAY",
  "7TH HEAVEN'S CAFE AND LODGING",
  "8 COLORS BEACH HOUSE RESORT",
  "8 RVE LANES REALTY CORP",
  "8 SUITES BY FAT JIMMY'S TAGAYTAY",
  "8 WAVES WATERPARK AND HOTEL",
  "8 ZHENSEN INN VENTURE CORP",
  "808 PRIME PRIME HOLDINGS, INC. (ISLANDS HOTEL)",
  "87 GUESTHOUSE",
  "88 HOTSPRING RESORT",
  "88TH AVENUE",
  "8990 LEISURE AND RESORTS CORPORATION (AZALEA HOTELS & RESIDENCES BORACAY)",
  "9175586896",
  "9178695808",
  "9497834122",
  "950 CONDOTEL",
  "999 HOTEL",
  "A & A BEACH RESORT / RESTAURANTS & CATERING SERVICES",
  "A AND A BED AND BREAKFAST",
  "A AND A SURFSIDE RESORT AND TRAVEL CORPORATION/ SURFSIDE BORACAY RESORT AND SPA",
  "A C E BUDGET HOTEL",
  "A HOTEL BAGUIO",
  "A PLACE TO REMEMBER INN",
  "A SQUARE PENSIONE",
  "A-DEN HOTEL",
  "A. R. HOTEL",
  "A.A. MEDRANO'S HOMESTAY",
  "A.B. CUEVA TRAVEL LODGE",
  "A&A ABE BAYFRONT INN",
  "A&A OF NORTH BEACH RESORT",
  "A&A PENSION HOUSE",
  "A&A PLAZA HOTEL",
  "A&B BEACH RESORT (AMELIA & BRUNO BEACH RESORT)",
  "A&F BEACH RESORT",
  "AA' TRAVELLERS PAD GUEST HOUSE",
  "AA'S PARK AND CAVE RESORT",
  "AAA APARTMENT",
  "AABANA BEACH & WATERSPORTS RESORT",
  "AB HOTEL *",
  "AB STAR SUITES",
  "ABAGATAN HOTELS INC.",
  "ABBEY OF TRANSFIGURATION  RETREAT AND GUESTHOUSE CENTER",
  "ABBY'S PLACE HOTEL",
  "ABC BUILDING LESSOR",
  "ABC HOTEL (FORMERLY ANGELES BEACH CLUB HOTEL)",
  "ABC HOTEL & HOMES CEBU",
  "ABC LANDMARK HOTEL",
  "ABCD RESORT",
  "ABELLA'S APARTELLE",
  "ABENGANA'S GUEST HOUSE TOUR AND SERVICES",
  "ABERRISE PENSION HOTEL",
  "ABITRIA RESIDENTIAL LEASING",
];

export const airportList = [
  "Ninoy Acquino International Airport T1 - (MNL)",
  "Ninoy Acquino International Airport T2 - (MNL)",
  "Ninoy Acquino International Airport T3 - (MNL)",
  "Clark International Airport (CRK)",
];

export const country = [
  "FR",
  "PH",
  "AF",
  "AL",
  "AD",
  "AO",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BT",
  "BO",
  "BA",
  "BW",
  "BR",
  "IO",
  "BN",
  "BG",
  "BF",
  "BI",
  "KH",
  "CM",
  "CA",
  "CV",
  "CW",
  "CF",
  "TD",
  "CL",
  "CN",
  "CO",
  "KM",
  "CD",
  "CG",
  "CR",
  "CI",
  "HR",
  "CU",
  "CW",
  "CY",
  "CZ",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "ET",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GR",
  "GD",
  "GP",
  "GU",
  "GT",
  "GN",
  "GW",
  "GY",
  "HT",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IL",
  "IT",
  "JM",
  "JP",
  "JO",
  " KZ",
  " KE",
  " KI",
  "XK",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MK",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "KP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PL",
  "PT",
  "PR",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "KN",
  "LC",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "KR",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SZ",
  "SE",
  "CH",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UY",
  "UZ",
  "VU",
  "VA",
  "VE",
  "VN",
  "YE",
  "ZM",
  "ZW",
];

export const symptomsList = [
  "Ageusia (Loss of taste)",
  "Altered Mental Status",
  "Anorexia",
  "Anosmia (Loss of smell)",
  "Asthenia",
  "Coryza (Colds)",
  "Cough",
  "Diarrhea",
  "Difficulty of Breathing",
  "Dizziness",
  "Fatigue",
  "Fever",
  "Headache",
  "Myalgia (Muscle Pain)",
  "Nausea",
  "Rashes",
  " Sore throat",
  "Vomiting",
  "Weakness",
];

