import React, { useEffect, useState } from "react";
import { Divwrapper, StyledError } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  getCountryListing,
  customerApplicationForm,
  getEmbassyListing,
} from "../../redux/applyNowSlice";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { encryptVal } from "../../utility/utility";
import { toast } from "react-toastify";
import moment from "moment";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";

const ApplyNow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const getOrderId = useSelector((state) => state.applyNow.orderIDs);
  const countryList = useSelector((state) => state.applyNow.countryList);
  const embassyList = useSelector((state) => state.applyNow.embassyList);
  

  // const [orderId, setOrderId] = useState("");
  let diff;

  const [initialValues, setInitialValues] = useState({
    visaType: "",
    entryType: "",
    countryOfApplication: "",
    applicationType: "Regular",
    embassy: "",
    email: "",
    confirmEmail: "",
    name: "",
    surname: "",
    sex: "",
    dateOfBirth: "",
    age: "",
    placeOfBirth: "",
    countryOfCitizenship: "",
    contactNumber: "",
    fatherName: "",
    motherName: "",
    houseNumber: "",
    street: "",
    town: "",
    province: "",
    postalCode: "",
    occupation: "",
    civilStatus: "",
    profile: "",
    passportNumber: "",
    issueDate: "",
    validDate: "",
    issuedBy: "",
    portOfEntry: "",
    lengthOfStay: "",
  });

  const applyNowSchema = yup.object({
    visaType: yup.string().required("Please select visa type."),
    entryType: yup.string().required("Please enter your entry type."),
    countryOfApplication: yup
      .string()
      .required("Please enter your country of application."),
    applicationType: yup.string().required("Please enter application type"),
    embassy: yup.string().required("Please select embassy."),
    email: yup.string().email().required("Please enter your email."),
    confirmEmail: yup
      .string()
      .email()
      .required("Please enter confirm email.")
      .oneOf([yup.ref("email"), null], "email don't match"),
    name: yup.string().required("Please enter name."),
    surname: yup.string().required("Please enter surname."),
    sex: yup.string().required("Please enter sex."),
    dateOfBirth: yup.date().required("Please enter date of birth."),
    age: yup.string().required("Please enter age."),
    placeOfBirth: yup.string().required("Please enter place of birth."),
    countryOfCitizenship: yup
      .string()
      .required("Please enter country of citizenship."),
    contactNumber: yup.string().required("Please enter contact number."),
    fatherName: yup.string().required("Please enter father name."),
    motherName: yup.string().required("Please enter mother name."),
    houseNumber: yup.string().required("Please enter house/room no."),
    street: yup.string().required("Please enter street."),
    town: yup.string().required("Please enter town."),
    province: yup.string().required("Please enter province."),
    postalCode: yup.string().required("Please enter postal code."),
    occupation: yup.string().required("Please enter occupation."),
    civilStatus: yup.string().required("Please select civil status."),
    profile: yup.string().required("Please upload profile picture"),
    passportNumber: yup.string().required("Please enter passport no."),
    issueDate: yup.string().required("Please select issue date."),
    validDate: yup.date().required("Please select valid date."),
    issuedBy: yup.string().required("Please enter issued by."),
    portOfEntry: yup.string().required("Please enter port of entry."),
    lengthOfStay: yup.string().required("Please enter length of stay."),
  });

  useEffect(() => {
    dispatch(getCountryListing());
    document.title = "Apply Now";

    if (initialValues.countryOfApplication) {
      dispatch(getEmbassyListing(initialValues?.countryOfApplication));
    }
  }, [dispatch, navigate, initialValues?.countryOfApplication]);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: applyNowSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("visaType", values?.visaType);
      formData.append("entryType", values?.entryType);
      formData.append("countryOfApplication", values.countryOfApplication);
      formData.append("applicationType", values.applicationType);
      formData.append("embassy", values.embassy);
      formData.append("email", values.email);
      formData.append("confirmEmail", values.confirmEmail);
      formData.append("name", values.name);
      formData.append("surname", values.surname);
      formData.append("sex", values.sex);
      formData.append(
        "dateOfBirth",
        moment(values.dateOfBirth).format("YYYY-MMMM-DD")
      );

      formData.append("age", values.age);

      formData.append("placeOfBirth", values.placeOfBirth);
      formData.append("countryOfCitizenship", values.countryOfCitizenship);
      formData.append("contactNumber", values.contactNumber);
      formData.append("fatherName", values.fatherName);

      formData.append("motherName", values.motherName);
      formData.append("houseNumber", values.houseNumber);
      formData.append("street", values.street);
      formData.append("town", values.town);
      formData.append("province", values.province);
      formData.append("postalCode", values.postalCode);
      formData.append("occupation", values.occupation);
      formData.append("civilStatus", values.civilStatus);
      formData.append("profile", values.profile);
      formData.append("passportNumber", values.passportNumber);
      formData.append(
        "issueDate",
        moment(values.issueDate).format("YYYY-MMMM-DD")
      );
      formData.append(
        "validDate",
        moment(values.validDate).format("YYYY-MMMM-DD")
      );
      formData.append("issuedBy", values.issuedBy);
      formData.append("portOfEntry", values.portOfEntry);
      formData.append("lengthOfStay", values.lengthOfStay);
      dispatch(customerApplicationForm(formData))
        .unwrap()
        .then((res) => {
          if (res.status == 1) {
            localStorage.removeItem("Id");
            localStorage.setItem("Id", encryptVal(res?.Id));
            navigate("/preview");
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
          } else {
            toast.error(`${res.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });

  diff =
    (new Date(values?.validDate)?.getDate() -
      new Date(values?.issueDate)?.getDate()) /
      30 +
    new Date(values?.validDate)?.getMonth() -
    new Date(values?.issueDate)?.getMonth() +
    12 *
      (new Date(values?.validDate)?.getFullYear() -
        new Date(values?.issueDate)?.getFullYear());

  return (
    <Divwrapper>
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>Application Form</h1>
          <p>General questions and answers are found on this page.</p>
        </div>
      </section>

      <section className="application">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-bot">
                <h2 className="heading">Application Form</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-lg-12 mt-12 mt-lg-12">
            <h3>Visa Details</h3>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Visa Type<span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control required travel_document"
                    name="visaType"
                    value={values.visaType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    <option value="Business">Business</option>
                    <option value="Tourism or Leisure">
                      Tourism or Leisure
                    </option>
                    <option value="Medical Treatment">Medical Treatment</option>
                    <option value="Private Yachtsmen/Sailor">
                      Private Yachtsmen/Sailor
                    </option>
                    <option value="Filmmaker">Filmmaker</option>
                    <option value="Journalist">Journalist</option>
                    <option value="Journalist">Athlete/Performer</option>
                    <option value="Journalist">others</option>
                  </select>
                  <StyledError>{errors.visaType}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Entry Type<span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control required travel_document"
                    name="entryType"
                    value={values.entryType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    <option value="single entry">Single Entry</option>
                    <option value="multiple entry(6 months)">
                      Multiple Entry(6 Months)
                    </option>
                    <option value="multiple entry(1 year)">
                      Multiple Entry(1 year)
                    </option>
                  </select>
                  <StyledError>{errors.entryType}</StyledError>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Country of Application<span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control required travel_document"
                    name="countryOfApplication"
                    value={values.countryOfApplication}
                    // onChange={handleChange}
                    onChange={(e) => {
                      setInitialValues({
                        ...initialValues,
                        countryOfApplication: e.target.value,
                      });
                      setFieldValue("countryOfApplication", e.target.value);
                    }}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    {countryList?.map((item, index) => {
                      return (
                        <option value={item.id}>{item.country_name}</option>
                      );
                    })}
                  </select>
                  <StyledError>{errors.countryOfApplication}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Application Type<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="applicationType"
                    name="applicationType"
                    value={values.applicationType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.applicationType}</StyledError>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Embassy/Consulate
                    <span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control required travel_document"
                    name="embassy"
                    value={values.embassy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    {embassyList?.map((item, index) => {
                      return (
                        <option value={item?.embassy_description}>
                          {item?.embassy_description}
                        </option>
                      );
                    })}
                  </select>
                  <StyledError>{errors.embassy}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Email<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.email}</StyledError>
                </div>
              </div>
              <div className="col-md-6">
                <label>
                  Confirm Email<span className="needed">*</span>{" "}
                </label>
                <input
                  type="text"
                  className="form-control required passport_number"
                  id="confirmEmail"
                  name="confirmEmail"
                  value={values.confirmEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-required="true"
                />
                <StyledError>{errors.confirmEmail}</StyledError>
              </div>

              <h3>Personal Details</h3>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Given Name(s)
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.name}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Surname
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="surname"
                    name="surname"
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.surname}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Sex
                    <span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control required travel_document"
                    name="sex"
                    value={values.sex}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    <option value="Passport ordinary/regular">Male</option>
                    <option value="Passport ordinary/regular">Female</option>
                    <option value="Passport ordinary/regular">X</option>
                  </select>
                  <StyledError>{errors.sex}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Date of Birth
                    <span className="needed">*</span>{" "}
                  </label>
                  <DatePicker
                    name="dateOfBirth"
                    className="form-control"
                    selected={values.dateOfBirth}
                    value={values.dateOfBirth}
                    maxDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => setFieldValue("dateOfBirth", date)}
                    // dateFormat="Y-MMMM-dd"
                    // dateFormat="MMMM dd,yyyy"
                    // autoComplete="off"
                  />
                  <StyledError>{errors.dateOfBirth}</StyledError>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Age
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="age"
                    name="age"
                    value={values.age}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.age}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Place of Birth<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="placeOfBirth"
                    name="placeOfBirth"
                    value={values.placeOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.placeOfBirth}</StyledError>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Country of Citizenship
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="countryOfCitizenship"
                    name="countryOfCitizenship"
                    value={values.countryOfCitizenship}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.countryOfCitizenship}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Contact Number
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="contactNumber"
                    name="contactNumber"
                    value={values.contactNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.contactNumber}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Father's Name<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="fatherName"
                    name="fatherName"
                    value={values.fatherName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.fatherName}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Mother's Name
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="motherName"
                    name="motherName"
                    value={values.motherName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.motherName}</StyledError>
                </div>
              </div>

              <h3>Applicant Address</h3>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Room/House No.
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="houseNumber"
                    name="houseNumber"
                    value={values.houseNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.houseNumber}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Street<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="street"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.street}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Town
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="town"
                    name="town"
                    value={values.town}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.town}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Province<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="province"
                    name="province"
                    value={values.province}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.province}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Postal Code
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="postalCode"
                    name="postalCode"
                    value={values.postalCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.postalCode}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Occupation<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="occupation"
                    name="occupation"
                    value={values.occupation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.occupation}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Civil Status
                    <span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control required travel_document"
                    name="civilStatus"
                    value={values.civilStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Widowed">Widowed</option>
                    <option value="Separated">Separated</option>
                    <option value="Divorced">Divorced</option>
                  </select>
                  <StyledError>{errors.civilStatus}</StyledError>
                </div>

                <div className="col-md-6">
                  <label>
                    Upload Profile Picture
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="profile"
                    accept="image/*"
                    onChange={(e) =>
                      setFieldValue("profile", e.currentTarget.files[0])
                    }
                    onBlur={handleBlur}
                    autoComplete="false"
                  />
                  <StyledError>{errors.profile}</StyledError>
                </div>
              </div>

              <h3>Passport Details</h3>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Passport/Travel Document no.
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="passportNumber"
                    name="passportNumber"
                    value={values.passportNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.passportNumber}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Date Issue
                    <span className="needed">*</span>{" "}
                  </label>
                  <DatePicker
                    name="issueDate"
                    className="form-control"
                    // placeholderText="Select Date"
                    selected={values.issueDate}
                    value={values.issueDate}
                    maxDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    // peekNextMonth
                    // onChange={(date) => {
                    //   setInitialValues({
                    //     ...initialValues,
                    //     issueDate: date,
                    //   });
                    //   setFieldValue("issueDate", date);
                    // }}
                    onChange={(date) => setFieldValue("issueDate", date)}
                    dateFormat="Y-MMMM-dd"
                    // dateFormat="MMMM dd,yyyy"
                    // autoComplete="off"
                  />
                  <StyledError>{errors.issueDate}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Valid Until
                    <span className="needed">*</span>{" "}
                  </label>
                  <DatePicker
                    name="validDate"
                    className="form-control"
                    // placeholderText="Select Date"
                    selected={values.validDate}
                    value={values.validDate}
                    // maxDate={new Date()}
                    minDate={values.issueDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    // peekNextMonth
                    // onChange={(date) => {
                    //   setInitialValues({
                    //     ...initialValues,
                    //     validDate: date,
                    //   });
                    //   setFieldValue("validDate", date);
                    // }}
                    onChange={(date) => setFieldValue("validDate", date)}
                    dateFormat="Y-MMMM-dd"
                    // dateFormat="MMMM dd,yyyy"
                    // autoComplete="off"
                  />
                  <StyledError>{errors.validDate}</StyledError>

                  <StyledError>
                    {values?.validDate !== "" && values?.issueDate !== ""
                      ? diff >= 6.0
                        ? null
                        : "Passport must be valid atleast 6 months"
                      : null}
                  </StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Issued by
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="issuedBy"
                    name="issuedBy"
                    value={values.issuedBy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.issuedBy}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Port of Entry (City)
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="portOfEntry"
                    name="portOfEntry"
                    value={values.portOfEntry}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.portOfEntry}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Length of Stay (Days)
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control required passport_number"
                    id="lengthOfStay"
                    name="lengthOfStay"
                    value={values.lengthOfStay}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.lengthOfStay}</StyledError>
                </div>
              </div>
              <div className="form-group btn-fom">
                <input
                  type="hidden"
                  className="application_save_type"
                  name="application_save"
                  value="Submit"
                />

                <button
                  className="btn-theme"
                  type="submit"
                  id="btn_individual_new"
                  name="application_save"
                  value="Submit"
                  // onclick=""
                >
                  CONTINUE FOR THE PREVIEW
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Divwrapper>
  );
};

export default ApplyNow;
