import styled from "styled-components";

export const StyledContact = styled.div`
  .in-banner {
    position: relative;
  }
  .in-banner img {
    width: 100%;

    @media (max-width: 767px) {
      min-height: 144px;
      object-fit: cover;
    }
  }
  .in-banner h1 {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    border-bottom: 2px solid #fff;
  }

  .text-ban {
    position: absolute;
    top: 50%;
    left: 19%;
    transform: translateY(-50%);

    @media (max-width: 767px) {
      left: 0%;
      padding: 0 15px;
    }

    @media (max-width: 1440px) {
      left: 6%;
    }
  }

  .about-us {
    padding: 96px 0;
  }

  & input {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    padding: 15px;
    border: 2px solid #000;
  }
  .btn {
    -webkit-border-radius: 28;
    -moz-border-radius: 28;
    border-radius: 28px;
    font-family: Arial;
    color: #ffffff;
    font-size: 20px;
    background: #aa0100;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    width: 20%;
    margin: 0 auto;
    border: none;
  }
  .btn:hover {
    background: #c70202;
    text-decoration: none;
  }
  @media only screen and (max-width: 600px) {
    .btn {
      -webkit-border-radius: 28;
      -moz-border-radius: 28;
      border-radius: 28px;
      font-family: Arial;
      color: #ffffff;
      font-size: 20px;
      background: #aa0100;
      padding: 10px 20px 10px 20px;
      text-decoration: none;
      width: 80%;
      margin: 0 auto;
    }
  }
  // // margin-bottom: 157px;
  // .form-control:focus {
  //   color: #212529;
  //   background-color: #fff;
  //   border-color: rgba(0, 0, 0, 0.3);
  //   outline: 0;
  //   box-shadow: 0 0 3px rgb(0 0 0 / 10%);
  // }

  // @media (max-width: 1366px) {
  //   margin-bottom: 100px;
  // }
  // @media (max-width: 991px) {
  //   margin-bottom: 80px;
  // }
  // @media (max-width: 767px) {
  //   margin-bottom: 50px;
  // }

  // @media (max-width: 560px) {
  //   margin-bottom: 30px;
  // }

  // .btn-lg {
  //   padding: 10px 16px;
  //   font-size: 18px;
  //   line-height: 1.3333333;
  //   border-radius: 6px;
  //   border: none;
  // }
  // .btn-success {
  //   color: #fff;
  //   background-color: #5cb85c;
  //   border-color: #4cae4c;
  // }
  // .btn&:hover {
  //   color: var(--bs-btn-hover-color);
  //   background-color: #68737d !important;
  //   border-color: #68737d;
  // }
  // .btn-success:hover {
  //   color: #fff;
  //   background-color: #449d44;
  //   border-color: #398439;
  // }
`;

export const StyledError = styled.p`
  color: red;
`;

export const StyledCaptcha = styled.div`
  .captcha {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 1rem;

    @media screen and (max-width: 480px) {
      width: 70%;
    }

    @media screen and (max-width: 425px) {
      width: 80%;
    }

    @media screen and (max-width: 375px) {
      width: 93%;
    }

    @media screen and (max-width: 375px) {
      width: 100%;
      float: left;
    }
  }
`;
