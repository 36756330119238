import React, { useCallback, useEffect, useState } from "react";
import { Divwrapper, StyledError, StyledTable } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {
  customerEtaApplicationForm,
  getCountryInfoListing,
  getCountryListing,
} from "../../redux/applyNowSlice";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  airlineList,
  airportOfDeparture,
  encryptVal,
  hotelsList,
  seaPort,
  suffix,
  travelPurpose,
  airportList,
  symptomsList,
} from "../../utility/utility";
import { toast } from "react-toastify";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EtravelForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const getOrderId = useSelector((state) => state.applyNow.orderIDs);
  const countryInfoList = useSelector(
    (state) => state.applyNow.countryInfoList
  );
  const [state1, setState1] = useState("");
  const [city1, setCity1] = useState("");
  const countryList = useSelector((state) => state.applyNow.countryList);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [barangay, setBarangay] = useState([]);
  // const [selected, setSelected] = useState("");
  const [airlineCode, setAirlineCode] = useState();
  const [flightNumber, setFlightNumber] = useState([]);
  const [hotelInfo, setHotelInfo] = useState([]);
  // const [showInput, setShowInput] = useState();
  const [countriesList, setCountriesList] = useState([]);
  const [someState, setSomeState] = useState([]);
  const [transportType, setTransportType] = useState();
  const [specialFlight, setSpecialFlight] = useState();
  const [terms, setTerms] = useState();
  const [stateCode, setStateCode] = useState();
  const [cityCode, setCityCode] = useState();
  const [travelType, setTravelType] = useState();
  const [arrivalState1, setArrivalState1] = useState();
  const [arrivalCity1, setArrivalCity1] = useState();

  const initialValues = {
    passportType: "philippine",
    travelType: "",
    transportationType: "",
    arrivalDate: "",
    departureDate: "",
    email: "",
    confirmEmail: "",
    terms: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    passportNumber: "",
    sex: "",
    dateOfBirth: "",
    citizenship: "",
    countryOfBirth: "",
    mobileNumber: "",
    country: "",
    state: "",
    city: "",
    barangay: "",
    houseNumber: "",
    address: "",
    travelArrivalDate: "",
    travelDepartureDate: "",
    countryOfDestination: "",
    destinationAddress: "",
    areYouOnOverSeasFilipinoWorker: "",
    ofwClassification: "",
    purposeOfTravel: "",
    travellerType: "",
    airlineName: "",
    flightNumber: "",
    specialFlight: "",
    specialFlightNumber: "",
    airportOfArrival: "",
    airportOfDeparture: "",
    vesselName: "",
    voyageNumber: "",
    seaPort: "",
    cabin: "",
    destinationType: "hotel",
    touristDestination: "",
    arrivalState: "",
    arrivalCity: "",
    arrivalHouseNumber: "",
    arrivalContact: "",
    arrivalAirport: "",
    arrivalCountryOfDestination: "",
    arrivalCountry: [],
    haveInfectiousDiseaseHistory: "no",
    haveYouBeenSick: "no",
    symptoms: [],
  };

  const EtravelFormSchema = yup.object({
    travelType: yup.string().required("Please select Travel type."),
    transportationType: yup
      .string()
      .required("Please select Transportation type."),
    arrivalDate: yup.string().when("travelType", (travelType, schema) => {
      if (travelType[0] == "arrival")
        return schema.required("Please enter date of arrival.");
      return schema;
    }),
    departureDate: yup.string().when("travelType", (travelType, schema) => {
      if (travelType[0] === "departure")
        return schema.required("Please enter date of departure.");
      return schema;
    }),
    email: yup.string().email().required("Please enter your email."),
    confirmEmail: yup
      .string()
      .email()
      .required("Please enter confirm email.")
      .oneOf([yup.ref("email"), null], "email don't match"),
    firstName: yup.string().required("Please enter first name."),
    lastName: yup.string().required("Please enter last name."),
    passportNumber: yup
      .string()
      .min(5, "Passport Number must be at least 5 characters.")
      .required("Please enter passport number."),
    sex: yup.string().required("Please select gender."),
    dateOfBirth: yup.date().required("Please enter date of birth."),
    citizenship: yup.string().required("Please select citizenship."),
    countryOfBirth: yup.string().required("Please select country of birth."),
    mobileNumber: yup.string().required("Please enter mobile number."),
    country: yup.string().required("Please select country."),
    state: yup.string().when("country", (country, schema) => {
      if (country[0] === "Philippines")
        return schema.required("Please select state.");
      return schema;
    }),
    city: yup.string().when("country", (country, schema) => {
      if (country[0] === "Philippines")
        return schema.required("Please select city.");
      return schema;
    }),
    barangay: yup.string().when("country", (country, schema) => {
      if (country[0] === "Philippines")
        return schema.required("Please select barangay.");
      return schema;
    }),
    houseNumber: yup.string().required("Please enter house no."),
    address: yup.string().required("Please enter address"),
    travelArrivalDate: yup
      .string()
      .when("arrivalDate", (arrivalDate, schema) => {
        if (arrivalDate[0])
          return schema.required("Please enter travel date of arrival.");
        return schema;
      }),
    travelDepartureDate: yup
      .string()
      .when("departureDate", (departureDate, schema) => {
        if (departureDate[0])
          return schema.required("Please enter travel date of departure.");
        return schema;
      }),
    countryOfDestination: yup
      .string()
      .when("travelType", (travelType, schema) => {
        if (travelType[0] === "departure")
          return schema.required("Please select country of destination.");
        return schema;
      }),
    destinationAddress: yup
      .string()
      .min(10, "Destination Address must be at least 10 characters.")
      .when("travelType", (travelType, schema) => {
        if (travelType[0] === "departure")
          return schema.required("Please enter destination address.");
        return schema;
      }),
    countryOfOrigin: yup.string().when("travelType", (travelType, schema) => {
      if (travelType[0] === "arrival")
        return schema.required("Please enter destination address.");
      return schema;
    }),
    areYouOnOverSeasFilipinoWorker: yup
      .string()
      .required("Please select Are you on overseas."),
    ofwClassification: yup
      .string()
      .when(
        "areYouOnOverSeasFilipinoWorker",
        (areYouOnOverSeasFilipinoWorker, schema) => {
          if (areYouOnOverSeasFilipinoWorker[0] === "yes")
            return schema.required("Please select OFW Classification.");
          return schema;
        }
      ),
    purposeOfTravel: yup.string().required("Please select purpose of travel."),
    travellerType: yup.string().required("Please select travel type."),
    airlineName: yup
      .string()
      .when("transportationType", (transportationType, schema) => {
        if (transportationType[0] === "air")
          return schema.required("Please enter airline name.");
        return schema;
      }),
    flightNumber: yup
      .string()
      .when("transportationType", (transportationType, schema) => {
        if (transportationType[0] === "air" && !specialFlight)
          return schema.required("Please enter Flight Number.");
        return schema;
      }),
    specialFlightNumber: yup
      .string()
      .when("specialFlight", (specialFlight, schema) => {
        if (specialFlight[0])
          return schema.required("Please enter Special Flight Number.");
        return schema;
      }),
    airportOfArrival: yup.string().when("travelType", (travelType, schema) => {
      if (travelType[0] === "arrival" && transportType === "air")
        return schema.required("Please enter destination address.");
      return schema;
    }),
    airportOfDeparture: yup
      .string()
      .when("travelType", (travelType, schema) => {
        if (travelType[0] === "departure" && transportType === "air")
          return schema.required("Please enter destination address.");
        return schema;
      }),
    vesselName: yup
      .string()
      .when("transportationType", (transportationType, schema) => {
        if (transportationType === "sea")
          return schema.required("Please enter vessel name.");
        return schema;
      }),
    voyageNumber: yup
      .string()
      .when("travellerType", (travellerType, schema) => {
        if (
          travellerType[0] === "vessel crew" ||
          travellerType[0] === "vessel passenger"
        )
          return schema.required("Please enter vessel name.");
        return schema;
      }),
    seaPort: yup.string().when("travellerType", (travellerType, schema) => {
      if (
        travellerType[0] === "vessel crew" ||
        travellerType[0] === "vessel passenger"
      )
        return schema.required("Please select  sea port.");
      return schema;
    }),
    cabin: yup.string().required("Please enter cabin number."),
    destinationType: yup.string().when("travelType", (travelType, schema) => {
      if (travelType[0] === "arrival")
        return schema.required("Please select tourist destination.");
      return schema;
    }),
    touristDestination: yup
      .string()
      .when("destinationType", (destinationType, schema) => {
        if (destinationType[0] === "hotel" && travelType === "arrival")
          return schema.required("Please select tourist destination.");
        return schema;
      }),
    arrivalState: yup
      .string()
      .when("destinationType", (destinationType, schema) => {
        if (destinationType[0] === "residence")
          return schema.required("Please select state.");
        return schema;
      }),
    arrivalCity: yup
      .string()
      .when("destinationType", (destinationType, schema) => {
        if (destinationType[0] === "residence")
          return schema.required("Please select city.");
        return schema;
      }),
    arrivalHouseNumber: yup
      .string()
      .when("destinationType", (destinationType, schema) => {
        if (destinationType[0] === "residence")
          return schema.required("Please enter house number.");
        return schema;
      }),
    arrivalContact: yup
      .string()
      .when("destinationType", (destinationType, schema) => {
        if (destinationType[0] == "hotel" && destinationType[0] === "residence")
          return schema.required("Please enter contact information.");
        return schema;
      }),
    arrivalAirport: yup
      .string()
      .when("destinationType", (destinationType, schema) => {
        if (destinationType[0] === "transit")
          return schema.required("Please select airport.");
        return schema;
      }),
    arrivalCountryOfDestination: yup
      .string()
      .when("destinationType", (destinationType, schema) => {
        if (destinationType[0] === "transit")
          return schema.required("Please select country of destination.");
        return schema;
      }),
    arrivalCountry: yup
      .array()
      .when("destinationType", (destinationType, schema) => {
        if (destinationType[0] === "port")
          return schema.required("Please select country.");
        return schema;
      }),
    symptoms: yup.array().when("haveYouBeenSick", (haveYouBeenSick, schema) => {
      if (haveYouBeenSick[0] === "yes")
        return schema.required("Please select atleast 1 symptoms.");
      return schema;
    }),
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: EtravelFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let hotelInformation = {
        specification: hotelInfo[0]?.specification,
        region: hotelInfo[0]?.region_name,
        municipality: hotelInfo[0]?.city,
        street: hotelInfo[0]?.street,
        contact_number: hotelInfo[0]?.name,
        email_address: hotelInfo[0]?.email_address,
      };
      let data = { ...values, ...hotelInformation };
      dispatch(customerEtaApplicationForm(data))
        .unwrap()
        .then((res) => {
          if (res.status == 1) {
            localStorage.removeItem("Id");
            localStorage.setItem("Id", encryptVal(res?.Id));
            navigate("/summary");
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
          } else {
            toast.error(`${res.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });

  useEffect(() => {
    dispatch(getCountryInfoListing());
    if (values?.country) {
      axios
        .get(
          "https://ws.etravel.gov.ph/api/v1/common/provinces?q=&paginate=0&order_by=name"
        )
        .then((res) => setState(res.data.data))
        .catch((error) => console.log(error));
    }
  }, [values?.country]);

  useEffect(() => {
    if (stateCode) {
      axios
        .get(
          `https://ws.etravel.gov.ph/api/v1/common/municipalities?province_code=${stateCode}&q=&paginate=0&order_by=name`
        )
        .then((res) => setCity(res.data.data))
        .catch((error) => console.log(error));
    }
  }, [stateCode]);

  useEffect(() => {
    if (cityCode) {
      axios
        .get(
          `https://ws.etravel.gov.ph/api/v1/common/barangays?municipality_code=${cityCode}&q=&paginate=0&order_by=name`
        )
        .then((res) => setBarangay(res.data.data))
        .catch((error) => console.log(error));
    }
  }, [cityCode]);

  useEffect(() => {
    if (values?.airlineName) {
      axios
        .get(
          `https://ws.etravel.gov.ph/api/v1/common/travel_companies?q=${values?.airlineName}&paginate=0&order_by=name&status_by=asc&transportation_type=AIR`
        )
        .then((res) => setAirlineCode(res?.data?.data[0]?.code))
        .catch((error) => console.log(error));
    }
  }, [values?.airlineName]);

  useEffect(() => {
    if (airlineCode !== undefined) {
      axios
        .get(
          `https://ws.etravel.gov.ph/api/v1/common/flight_numbers?travel_company_code=${airlineCode}&q=&paginate=0&order_by=name&status_by=asc&transportation_type=AIR`
        )
        .then((res) => setFlightNumber(res.data.data))
        .catch((error) => console.log(error));
    }
  }, [airlineCode]);

  useEffect(() => {
    if (values?.touristDestination) {
      axios
        .get(
          `https://ws.etravel.gov.ph/api/v1/common/hotels?q=${values?.touristDestination}&paginate=1&per_page=100&order_by=name&status_by=asc`
        )
        .then((res) => setHotelInfo(res.data.data))
        .catch((error) => console.log(error));
    }
  }, [values?.touristDestination]);

  const stateOptions = state?.map((item, index) => {
    return { label: item?.name, value: item?.code };
  });

  const cityOptions = city?.map((item, index) => {
    return { label: item?.name, value: item?.code };
  });

  useEffect(() => {
    dispatch(getCountryListing());
    document.title = "Apply Now";
  }, [dispatch, navigate]);

  const [countryFields, setCountryFields] = useState([
    {
      arrivalCountry: "",
    },
  ]);

  const addInputField = () => {
    setCountryFields([
      ...countryFields,
      {
        arrivalCountry: "",
      },
    ]);
  };

  const removeInputFields = (index) => {
    let rows = [...countryFields];
    rows.splice(index, 1);
    setCountryFields(rows);
    const updatedCountriesList = rows.map((item) => item.arrivalCountry);
    setFieldValue("arrivalCountry", updatedCountriesList);
  };

  const handleChanges = useCallback(
    (index, evnt) => {
      const { name, value } = evnt.target;
      const updatedList = [...countryFields];
      updatedList[index][name] = value;
      setCountryFields(updatedList);
      const updatedCountriesList = updatedList.map(
        (item) => item.arrivalCountry
      );
      setFieldValue("arrivalCountry", updatedCountriesList);
    },
    [countryFields]
  );

  const [symptomFields, setSymptomFields] = useState([
    {
      symptoms: "",
    },
  ]);

  const addSymptomField = () => {
    setSymptomFields([
      ...symptomFields,
      {
        symptoms: "",
      },
    ]);
  };

  const removeSymptomFields = (index) => {
    let rows = [...symptomFields];
    rows.splice(index, 1);
    setSymptomFields(rows);
    const updatedSymptomsList = rows.map((item) => item.symptoms);
    setFieldValue("symptoms", updatedSymptomsList);
  };

  const handleSymptomChanges = useCallback(
    (index, evnt) => {
      const { name, value } = evnt.target;
      const symptomList = [...symptomFields];
      symptomList[index][name] = value;
      setSymptomFields(symptomList);
      const updatedSymptomsList = symptomList.map((item) => item.symptoms);
      setFieldValue("symptoms", updatedSymptomsList);
    },
    [symptomFields]
  );

  return (
    <Divwrapper>
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>Application Form</h1>
          <p>General questions and answers are found on this page.</p>
        </div>
      </section>

      <section className="application">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-bot">
                <h2 className="heading">Application Form</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-lg-12 mt-12 mt-lg-12">
            <h3>Register</h3>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="passportType"
                      id="inlineRadio1"
                      value={"Regular Processing"}
                      defaultChecked={true}
                      onChange={(e) =>
                        setFieldValue("passportType", e.target.value)
                      }
                      onBlur={handleBlur}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      PHILIPPINE PASSPORT HOLDER
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="passportType"
                      id="inlineRadio2"
                      value={"foreign"}
                      onChange={(e) =>
                        setFieldValue("passportType", e.target.value)
                      }
                      onBlur={handleBlur}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      FOREIGN PASSPORT HOLDER
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Travel Type<span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="travelType"
                    value={values.travelType}
                    onChange={(e) => {
                      setFieldValue("travelType", e.target.value);
                      setTravelType(e.target.value);
                    }}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Travel Type</option>
                    <option value="arrival">
                      Arrival (Entering the Philippines)
                    </option>
                    <option value="departure">
                      Departure (Leaving the Philippines)
                    </option>
                  </select>
                  <StyledError>{errors.travelType}</StyledError>
                </div>

                <div className="col-md-6">
                  <label>
                    Transportation Type<span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="transportationType"
                    value={values.transportationType}
                    onChange={(e) => {
                      setFieldValue("transportationType", e.target.value);
                      setTransportType(e.target.value);
                    }}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Transportation Type</option>
                    <option value="air">AIR</option>
                    <option value="sea">SEA</option>
                  </select>
                  <StyledError>{errors.transportationType}</StyledError>
                </div>

                {values.travelType === "arrival" &&
                  values?.transportationType === "sea" && (
                    <label className="checkbox-inline">
                      <input
                        type="checkbox"
                        id="areYouDisembarking"
                        name="areYouDisembarking"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />{" "}
                      Are you disembarking?
                    </label>
                  )}
              </div>

              <div className="row">
                {values?.travelType === "arrival" && (
                  <div className="col-md-6">
                    <label>
                      Date of Arrival(MM/DD/YYYY)
                      <span className="needed">*</span>{" "}
                    </label>
                    <DatePicker
                      name="arrivalDate"
                      className="form-control"
                      selected={values.arrivalDate}
                      value={values.arrivalDate}
                      placeholderText="Date of(MM/DD/YYYY)"
                      minDate={new Date()}
                      maxDate={new Date().setDate(new Date().getDate() + 4)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={(date) => setFieldValue("arrivalDate", date)}
                    />
                    <StyledError>{errors.arrivalDate}</StyledError>
                  </div>
                )}

                {values?.travelType === "departure" && (
                  <div className="col-md-6">
                    <label>
                      Date of Departure(MM/DD/YYYY)
                      <span className="needed">*</span>{" "}
                    </label>
                    <DatePicker
                      name="departureDate"
                      className="form-control"
                      selected={values.departureDate}
                      value={values.departureDate}
                      placeholderText="Date of(MM/DD/YYYY)"
                      minDate={new Date()}
                      maxDate={new Date().setDate(new Date().getDate() + 4)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={(date) => setFieldValue("departureDate", date)}
                    />
                    <StyledError>{errors.departureDate}</StyledError>
                  </div>
                )}

                <div className="col-md-6">
                  <label>
                    Enter Email<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.email}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Re-Enter Email<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="confirmEmail"
                    name="confirmEmail"
                    value={values.confirmEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.confirmEmail}</StyledError>
                </div>
              </div>

              <h3>Personal Profile</h3>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    First Name
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.firstName}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Middle Name (Optional)
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="middleName"
                    name="middleName"
                    value={values.middleName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.middleName}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Last Name
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.lastName}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Suffix(Optional)
                    <span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="suffix"
                    value={values.suffix}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    {suffix?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <StyledError>{errors.suffix}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Passport Number
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="passportNumber"
                    name="passportNumber"
                    value={values.passportNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.passportNumber}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Sex<span className="needed">*</span>{" "}
                  </label>

                  <select
                    className="form-control"
                    name="sex"
                    value={values.sex}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    <option value="female">FEMALE</option>
                    <option value="male">MALE</option>
                  </select>

                  <StyledError>{errors.sex}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Birth Date(MM/DD/YYYY)
                    <span className="needed">*</span>{" "}
                  </label>
                  <DatePicker
                    name="dateOfBirth"
                    className="form-control"
                    selected={values.dateOfBirth}
                    value={values.dateOfBirth}
                    placeholderText="Date of(MM/DD/YYYY)"
                    maxDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => setFieldValue("dateOfBirth", date)}
                  />
                  <StyledError>{errors.dateOfBirth}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Citizenship
                    <span className="needed">*</span>{" "}
                  </label>

                  <select
                    className="form-control"
                    name="citizenship"
                    value={values.citizenship}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    {countryInfoList?.map((item, index) => {
                      return (
                        <option key={index} value={item?.citizenship}>
                          {item?.citizenship}
                        </option>
                      );
                    })}
                  </select>
                  <StyledError>{errors.citizenship}</StyledError>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Country Of Birth
                    <span className="needed">*</span>{" "}
                  </label>

                  <select
                    className="form-control"
                    name="countryOfBirth"
                    value={values.countryOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    {countryInfoList?.map((item, index) => {
                      return (
                        <option key={index} value={item?.country_name}>
                          {item?.country_name}
                        </option>
                      );
                    })}
                  </select>
                  <StyledError>{errors.countryOfBirth}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Mobile Number
                    <span className="needed">*</span>{" "}
                  </label>
                  <PhoneInput
                    // type="number"
                    country={"ph"}
                    name="mobileNumber"
                    enableSearch={true}
                    onBlur={handleBlur}
                    placeholder="Mobile Number"
                    value={values.mobileNumber}
                    onChange={(phone) => setFieldValue("mobileNumber", phone)}
                  />

                  <StyledError>{errors.mobileNumber}</StyledError>
                </div>
              </div>

              <h3>Address Information</h3>

              <div className="row">
                <div className="col-md-6">
                  <label>
                    Country
                    <span className="needed">*</span>{" "}
                  </label>

                  <select
                    className="form-control travel_document"
                    name="country"
                    value={values.country}
                    onChange={(e) => {
                      setFieldValue("country", e.target.value);
                    }}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    {countryInfoList?.map((item, index) => {
                      return (
                        <option key={index} value={item?.country_name}>
                          {item?.country_name}
                        </option>
                      );
                    })}
                    <StyledError>{errors.country}</StyledError>
                  </select>
                </div>

                {values?.country === "Philippines" && (
                  <>
                    <div className="col-md-6">
                      <label>
                        State/Province
                        <span className="needed">*</span>{" "}
                      </label>
                      <Select
                        options={stateOptions}
                        onChange={(option) => {
                          setFieldValue("state", option.label);
                          setState1(option.label);
                          setStateCode(option.value);
                        }}
                        value={stateOptions.filter(function (option) {
                          return option.label === state1;
                        })}
                        label="Single select"
                        className="form-control"
                      />
                      <StyledError>{errors.state}</StyledError>
                    </div>

                    <div className="col-md-6">
                      <label>
                        City/Municipality<span className="needed">*</span>{" "}
                      </label>
                      <Select
                        options={cityOptions}
                        onChange={(option) => {
                          setFieldValue("city", option.label);
                          setCity1(option.label);
                          setCityCode(option.value);
                        }}
                        value={cityOptions.filter(function (option) {
                          return option.label === city1;
                        })}
                        className="form-control"
                        label="Single select"
                      />
                      <StyledError>{errors.city}</StyledError>
                    </div>

                    <div className="col-md-6">
                      <label>
                        Barangay<span className="needed">*</span>{" "}
                      </label>
                      <select
                        className="form-control"
                        name="barangay"
                        value={values.barangay}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-required="true"
                      >
                        <option value="">Please Select</option>
                        {barangay?.map((item, index) => {
                          return (
                            <option key={index} value={item?.name}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>

                      <StyledError>{errors.barangay}</StyledError>
                    </div>
                  </>
                )}

                <div className="col-md-6">
                  <label>
                    House No./Bldg./Street
                    <span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="houseNumber"
                    name="houseNumber"
                    value={values.houseNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.houseNumber}</StyledError>
                </div>
                <div className="col-md-6">
                  <label>
                    Address Line 2 (Optional)<span className="needed">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  />
                  <StyledError>{errors.address}</StyledError>
                </div>
              </div>

              <h3>
                Travel Details -{" "}
                {values?.travelType === "arrival"
                  ? "Philippine Arrival"
                  : values?.travelType === "departure"
                  ? "DEPARTURE"
                  : null}{" "}
                via
                {values?.transportationType === "air"
                  ? "(AIR)"
                  : values?.transportationType === "sea"
                  ? "(SEA)"
                  : null}
              </h3>

              {values?.arrivalDate ? (
                <div className="col-md-6">
                  <label>
                    Date of Arrival(MM/DD/YYYY)
                    <span className="needed">*</span>{" "}
                  </label>
                  <DatePicker
                    name="travelArrivalDate"
                    className="form-control"
                    selected={values.travelArrivalDate}
                    value={
                      values.arrivalDate
                        ? (values.travelArrivalDate = values.arrivalDate)
                        : ""
                    }
                    placeholderText="Date of(MM/DD/YYYY)"
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + 4)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) =>
                      setFieldValue("travelArrivalDate", date)
                    }
                  />
                  <StyledError>{errors.travelArrivalDate}</StyledError>
                </div>
              ) : values?.departureDate ? (
                <div className="col-md-6">
                  <label>
                    Date of Departure(MM/DD/YYYY)
                    <span className="needed">*</span>{" "}
                  </label>
                  <DatePicker
                    name="travelDepartureDate"
                    className="form-control"
                    selected={values.travelDepartureDate}
                    value={
                      values.departureDate
                        ? (values.travelDepartureDate = values.departureDate)
                        : ""
                    }
                    placeholderText="Date of(MM/DD/YYYY)"
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + 4)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) =>
                      setFieldValue("travelDepartureDate", date)
                    }
                  />
                  <StyledError>{errors.travelDepartureDate}</StyledError>
                </div>
              ) : null}

              {values.travelType === "arrival" ? (
                <div className="col-md-6">
                  <label>
                    Country of Origin
                    <span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="countryOfOrigin"
                    value={values.countryOfOrigin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    {countryList?.map((item, index) => {
                      return (
                        <option key={index} value={item?.country_name}>
                          {item?.country_name}
                        </option>
                      );
                    })}
                  </select>
                  <StyledError>{errors.countryOfOrigin}</StyledError>
                </div>
              ) : values.travelType === "departure" ? (
                <>
                  <div className="col-md-6">
                    <label>
                      Country of Destination
                      <span className="needed">*</span>{" "}
                    </label>
                    <select
                      className="form-control"
                      name="countryOfDestination"
                      value={values.countryOfDestination}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-required="true"
                    >
                      <option value="">Please Select</option>
                      {countryList?.map((item, index) => {
                        return (
                          <option key={index} value={item?.country_name}>
                            {item?.country_name}
                          </option>
                        );
                      })}
                    </select>
                    <StyledError>{errors.countryOfDestination}</StyledError>
                  </div>

                  <div className="col-md-6">
                    <label>
                      Destination Address<span className="needed">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="destinationAddress"
                      name="destinationAddress"
                      value={values.destinationAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-required="true"
                    />
                    <StyledError>{errors.destinationAddress}</StyledError>
                  </div>
                </>
              ) : null}

              <div className="col-md-6">
                <label>
                  Are you on Overseas Filipino Worker(OFW)
                  <span className="needed">*</span>{" "}
                </label>
                <select
                  className="form-control"
                  name="areYouOnOverSeasFilipinoWorker"
                  value={values.areYouOnOverSeasFilipinoWorker}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-required="true"
                >
                  <option value="">Please Select</option>
                  <option value="yes">YES</option>
                  <option value="no">NO</option>
                </select>
                <StyledError>
                  {errors.areYouOnOverSeasFilipinoWorker}
                </StyledError>
              </div>

              {values.areYouOnOverSeasFilipinoWorker === "yes" && (
                <div className="col-md-6">
                  <label>
                    OFW Classification
                    <span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="ofwClassification"
                    value={values.ofwClassification}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    <option value="landbase">LANDBASE</option>
                    <option value="seabase">SEABASE</option>
                  </select>
                  <StyledError>{errors.ofwClassification}</StyledError>
                </div>
              )}
              <div className="col-md-6">
                <label>
                  Purpose of Travel<span className="needed">*</span>{" "}
                </label>
                <select
                  className="form-control"
                  name="purposeOfTravel"
                  value={values.purposeOfTravel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-required="true"
                >
                  <option value="">Please Select</option>
                  {travelPurpose?.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>

                <StyledError>{errors.purposeOfTravel}</StyledError>
              </div>

              <div className="col-md-6">
                <label>
                  Traveller Type
                  <span className="needed">*</span>{" "}
                </label>
                <select
                  className="form-control"
                  name="travellerType"
                  value={values.travellerType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-required="true"
                >
                  <option value="">Please Select</option>

                  {values.transportationType === "air" ? (
                    <>
                      <option value="aircraft passenger">
                        AIRCRAFT PASSENGER
                      </option>
                      <option value="flight crew">FLIGHT CREW</option>
                    </>
                  ) : values.transportationType === "sea" ? (
                    <>
                      <option value="cruise crew">CRUISE CREW</option>
                      <option value="cruise passenger">CRUISE PASSENGER</option>
                      <option value="vessel crew">VESSEL CREW</option>
                      <option value="vessel passenger">VESSEL PASSENGER</option>
                    </>
                  ) : null}
                </select>
                <StyledError>{errors.travellerType}</StyledError>

                {(values.travellerType === "vessel crew" ||
                  values.travellerType === "vessel passenger") && (
                  <div className="col-md-6">
                    <label>
                      Voyage Number
                      <span className="needed">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="voyageNumber"
                      name="voyageNumber"
                      value={values.voyageNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-required="true"
                    />
                    <StyledError>{errors.voyageNumber}</StyledError>
                  </div>
                )}

                {values.transportationType === "sea" && (
                  <div className="col-md-6">
                    <label>
                      Vessel Name
                      <span className="needed">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="vesselName"
                      name="vesselName"
                      value={values.vesselName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-required="true"
                    />
                    <StyledError>{errors.vesselName}</StyledError>
                  </div>
                )}
              </div>

              {(values.travellerType === "vessel crew" ||
                values.travellerType === "vessel passenger") && (
                <div className="col-md-6">
                  <label>
                    Seaport of{" "}
                    {values?.travelType === "arrival"
                      ? "Arrival"
                      : values?.travelType === "departure"
                      ? "Departure"
                      : null}
                    <span className="needed">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="seaPort"
                    value={values.seaPort}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-required="true"
                  >
                    <option value="">Please Select</option>
                    {seaPort?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>

                  <StyledError>{errors.seaPort}</StyledError>
                </div>
              )}
              {values.transportationType === "air" && (
                <>
                  <div className="col-md-6">
                    <label>
                      Name of Airline
                      <span className="needed">*</span>{" "}
                    </label>
                    <select
                      className="form-control"
                      name="airlineName"
                      value={values.airlineName}
                      onChange={(e) => {
                        setFieldValue("airlineName", e.target.value);
                      }}
                      onBlur={handleBlur}
                      aria-required="true"
                    >
                      <option value="">Please Select</option>
                      {airlineList?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <StyledError>{errors.airlineName}</StyledError>
                  </div>

                  {values?.travelType === "arrival" &&
                    values?.transportationType === "air" && (
                      <div className="col-md-12 mt-5">
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            id="specialFlight"
                            name="specialFlight"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("specialFlight", e.target.checked);
                              setSpecialFlight(e.target.checked);
                            }}
                          />{" "}
                          Special Flight?
                        </label>
                        <StyledError>{errors.specialFlight}</StyledError>
                      </div>
                    )}

                  {values?.specialFlight ? (
                    <div className="col-md-6">
                      <label>
                        Specify Special Flight Number
                        <span className="needed">*</span>{" "}
                      </label>
                      <input
                        className="form-control"
                        id="specialFlightNumber"
                        name="specialFlightNumber"
                        value={values.specialFlightNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-required="true"
                      />
                      <StyledError>{errors.specialFlightNumber}</StyledError>
                      <p>
                        Misdeclaration of special flight will cause travel delay
                      </p>
                    </div>
                  ) : values?.travelType == "arrival" &&
                    values?.transportationType === "air" ? (
                    <div className="col-md-6">
                      <label>
                        Flight Number
                        <span className="needed">*</span>{" "}
                      </label>
                      <select
                        className="form-control"
                        id="flightNumber"
                        name="flightNumber"
                        value={values.flightNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-required="true"
                      >
                        <option value="">Please Select</option>
                        {flightNumber?.map((item, index) => {
                          return (
                            <option key={index} value={item.code}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <StyledError>{errors.flightNumber}</StyledError>
                      <p>
                        Notice: Please fill in the correct flight number in your
                        scheduled travel. Relative to the NAIA Airport Terminal
                        reassignments of airline companies, please disregard the
                        auto-fill detail of the airport terminal based on your
                        declared flight number.
                      </p>
                    </div>
                  ) : values?.travelType == "departure" &&
                    values?.transportationType === "air" ? (
                    <div className="col-md-6">
                      <label>
                        Flight Number
                        <span className="needed">*</span>{" "}
                      </label>
                      <input
                        className="form-control"
                        id="flightNumber"
                        name="flightNumber"
                        value={values.flightNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-required="true"
                      />

                      <StyledError>{errors.flightNumber}</StyledError>
                    </div>
                  ) : null}

                  {values.travelType === "departure" &&
                  values?.transportationType === "air" ? (
                    <div className="col-md-6">
                      <label>
                        Airport of Departure
                        <span className="needed">*</span>{" "}
                      </label>
                      <select
                        className="form-control"
                        name="airportOfDeparture"
                        value={values.airportOfDeparture}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-required="true"
                      >
                        <option value="">Please Select</option>
                        {airportOfDeparture?.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <StyledError>{errors.airportOfDeparture}</StyledError>
                    </div>
                  ) : values.travelType === "arrival" &&
                    values?.transportationType === "air" ? (
                    <div className="col-md-6">
                      <label>
                        Airport of Arrival
                        <span className="needed">*</span>{" "}
                      </label>
                      <select
                        className="form-control"
                        name="airportOfArrival"
                        value={values?.airportOfArrival}
                        onChange={(e) => {
                          e.target.value === ""
                            ? setFieldValue(
                                "airportOfArrival",
                                flightNumber[0]?.travel_port_name
                              )
                            : setFieldValue("airportOfArrival", e.target.value);
                        }}
                        onBlur={handleBlur}
                        aria-required="true"
                      >
                        <option value="">Please Select</option>
                        {airportOfDeparture?.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <StyledError>{errors.airportOfArrival}</StyledError>
                    </div>
                  ) : null}
                </>
              )}

              <div className="col-md-6">
                <label>
                  Cabin/Bed Number(Optional)
                  <span className="needed">*</span>{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cabin"
                  name="cabin"
                  value={values.cabin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-required="true"
                />
                <StyledError>{errors.cabin}</StyledError>
              </div>

              {values?.travelType === "arrival" && (
                <>
                  <h3>Destination Upon Arrival in the Philippines</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio4"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="destinationType"
                            id="inlineRadio4"
                            value={"hotel"}
                            defaultChecked={true}
                            onChange={(e) =>
                              setFieldValue("destinationType", e.target.value)
                            }
                            onBlur={handleBlur}
                          />
                          Hotel/Resort
                        </label>
                      </div>

                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio5"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="destinationType"
                            id="inlineRadio5"
                            value={"residence"}
                            onChange={(e) =>
                              setFieldValue("destinationType", e.target.value)
                            }
                            onBlur={handleBlur}
                          />
                          Residence
                        </label>
                      </div>

                      {values?.transportationType === "air" ? (
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio6"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="destinationType"
                              id="inlineRadio6"
                              value={"transit"}
                              onChange={(e) =>
                                setFieldValue("destinationType", e.target.value)
                              }
                              onBlur={handleBlur}
                            />
                            Transit
                          </label>
                        </div>
                      ) : values?.transportationType === "sea" ? (
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio7"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="destinationType"
                              id="inlineRadio7"
                              value={"port"}
                              onChange={(e) =>
                                setFieldValue("destinationType", e.target.value)
                              }
                              onBlur={handleBlur}
                            />
                            Port
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {values?.destinationType === "hotel" &&
                  values?.travelType === "arrival" ? (
                    <div className="col-md-6">
                      <label>
                        Hotel/Resort/Tourist Destination
                        <span className="needed">*</span>{" "}
                      </label>
                      <select
                        className="form-control"
                        name="touristDestination"
                        value={values.touristDestination}
                        onChange={(e) => {
                          setFieldValue("touristDestination", e.target.value);
                        }}
                        onBlur={handleBlur}
                        aria-required="true"
                      >
                        <option value="">Please Select</option>
                        {hotelsList?.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <StyledError>{errors.touristDestination}</StyledError>
                    </div>
                  ) : values?.destinationType === "residence" ? (
                    <>
                      <div className="col-md-12 mt-5">
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            id="terms"
                            name="terms"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              let isCheck = e.target.checked;
                              setFieldValue("terms", isCheck);
                              if (isCheck) {
                                setFieldValue("arrivalState", values?.state);
                                setFieldValue("arrivalCity", values?.state);
                                setFieldValue(
                                  "arrivalHouseNumber",
                                  values?.houseNumber
                                );
                              } else {
                                setFieldValue("arrivalState", "");
                                setFieldValue("arrivalCity", "");
                                setFieldValue("arrivalHouseNumber", "");
                              }
                              setTerms(isCheck);
                            }}
                          />{" "}
                          Same as Permanent Country of Residence.
                        </label>
                        <StyledError>{errors.terms}</StyledError>
                      </div>

                      <div className="col-md-6">
                        <Select
                          name="arrivalState"
                          options={stateOptions}
                          onChange={(option) => {
                            setFieldValue("arrivalState", option.label);
                            setArrivalState1(option.label);
                            setStateCode(option.value);
                          }}
                          value={
                            values?.terms
                              ? values?.city
                                ? stateOptions.filter(function (option) {
                                    return option.label === values.state;
                                  })
                                : stateOptions.filter(function (option) {
                                    return option.label === arrivalState1;
                                  })
                              : values.arrivalState
                          }
                          label="Single select"
                          className="form-control"
                        />

                        <StyledError>{errors.arrivalState}</StyledError>
                      </div>

                      <div className="col-md-6">
                        <label>
                          City/Municipality<span className="needed">*</span>{" "}
                        </label>
                        <Select
                          name="arrivalCity"
                          options={cityOptions}
                          onChange={(option) => {
                            setFieldValue("arrivalCity", option.label);
                            setArrivalCity1(option.label);
                          }}
                          value={
                            values?.terms
                              ? values?.city
                                ? cityOptions.filter(function (option) {
                                    return option.label === values.city;
                                  })
                                : cityOptions.filter(function (option) {
                                    return option.label === arrivalCity1;
                                  })
                              : values.arrivalCity
                          }
                          label="Single select"
                          className="form-control"
                        />
                        <StyledError>{errors.arrivalCity}</StyledError>
                      </div>

                      <div className="col-md-6">
                        <label>
                          House No./Bldg./Street
                          <span className="needed">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="houseNumber"
                          name="arrivalHouseNumber"
                          value={
                            values?.terms
                              ? (values.arrivalHouseNumber = values.houseNumber)
                              : values.arrivalHouseNumber
                          }
                          onChange={(e) => {
                            setFieldValue("arrivalHouseNumber", e.target.value);
                          }}
                          onBlur={handleBlur}
                          aria-required="true"
                        />
                        <StyledError>{errors.houseNumber}</StyledError>
                      </div>
                    </>
                  ) : values?.destinationType === "transit" ? (
                    <>
                      <div className="col-md-6">
                        <label>
                          Airport
                          <span className="needed">*</span>{" "}
                        </label>
                        <select
                          className="form-control"
                          name="arrivalAirport"
                          value={values.arrivalAirport}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          aria-required="true"
                        >
                          <option value="">Please Select</option>
                          {airportList?.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                        <StyledError>{errors.arrivalAirport}</StyledError>
                      </div>
                      <div className="col-md-6">
                        <label>
                          Country of Destination
                          <span className="needed">*</span>{" "}
                        </label>
                        <select
                          className="form-control"
                          name="arrivalCountryOfDestination"
                          value={values.arrivalCountryOfDestination}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          aria-required="true"
                        >
                          <option value="">Please Select</option>
                          {countryList?.map((item, index) => {
                            return (
                              <option key={index} value={item?.country_name}>
                                {item?.country_name}
                              </option>
                            );
                          })}
                        </select>
                        <StyledError>
                          {errors.arrivalCountryOfDestination}
                        </StyledError>
                      </div>
                    </>
                  ) : values?.destinationType === "port" ? (
                    <>
                      <div className="col-md-6">
                        <label>
                          Airport
                          <span className="needed">*</span>{" "}
                        </label>
                        <select
                          className="form-control"
                          name="airport"
                          value={values.airport}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          aria-required="true"
                        >
                          <option value="">Please Select</option>
                          {airportOfDeparture?.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                        <StyledError>{errors.airport}</StyledError>
                      </div>
                    </>
                  ) : null}

                  {values?.touristDestination &&
                    values?.destinationType === "hotel" && (
                      <StyledTable>
                        <table>
                          <tbody>
                            <tr>
                              <td>Specification</td>
                              <td>{hotelInfo[0]?.specification}</td>
                            </tr>
                            <tr>
                              <td>Region</td>
                              <td>{hotelInfo[0]?.region_name}</td>
                            </tr>

                            <tr>
                              <td>Municipality/City</td>
                              <td>{hotelInfo[0]?.city}</td>
                            </tr>

                            <tr>
                              <td>House No./Bldg./Street</td>
                              <td>{hotelInfo[0]?.street}</td>
                            </tr>

                            <tr>
                              <td>Contact Numbers</td>
                              <td>{hotelInfo[0]?.contact_no}</td>
                            </tr>

                            <tr>
                              <td>Email Addresses</td>
                              <td>{hotelInfo[0]?.email_address}</td>
                            </tr>
                          </tbody>
                        </table>
                      </StyledTable>
                    )}

                  {(values?.destinationType === "hotel" ||
                    values?.destinationType === "residence" ||
                    values?.destinationType === "port") && (
                    <div className="col-md-6">
                      <label>
                        Contact Information
                        <span className="needed">*</span>{" "}
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="arrivalContact"
                        name="arrivalContact"
                        value={values.arrivalContact}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-required="true"
                      />
                      <StyledError>{errors.arrivalContact}</StyledError>
                      <p>
                        Place the correct Philippine Mobile Number or
                        Landline/Hotel Contact Number
                      </p>
                    </div>
                  )}

                  <h3>Health Declaration </h3>

                  <p>
                    As of July 22, 2023, No Covid-19 test or Vaccination
                    requirement when traveling to the Philippines.
                  </p>

                  <div className="row">
                    <p>
                      Country(ies) worked, visited and transited in the last 30
                      days (optional)
                    </p>
                    {countryFields.map((data, index) => {
                      const { arrivalCountry } = data;
                      return (
                        <>
                          <div className="col-md-6" key={index}>
                            <label>
                              Country<span className="needed">*</span>{" "}
                            </label>
                            <select
                              className="form-control"
                              name="arrivalCountry"
                              value={data.arrivalCountry}
                              onChange={(evnt) => handleChanges(index, evnt)}
                              onBlur={handleBlur}
                              aria-required="true"
                            >
                              <option value="">Please Select</option>
                              {countryInfoList?.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={item?.country_name}
                                  >
                                    {item?.country_name}
                                  </option>
                                );
                              })}
                            </select>

                            <StyledError>{errors.city}</StyledError>
                          </div>
                          {index == countryFields?.length - 1 ? (
                            <div className="col-md-6 btn-wrapper">
                              <div>
                                <button
                                  type="button"
                                  className="add-button"
                                  onClick={addInputField}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          ) : null}

                          <div className="col-md-6 btn-wrapper">
                            {countryFields.length !== 1 &&
                            index < countryFields?.length - 1 ? (
                              <button
                                type="button"
                                className="btn-theme btn"
                                onClick={removeInputFields}
                              >
                                Delete
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        Have you had any history of exposure to a person who is
                        sick or known to have communicable/infectious disease in
                        the past 30 days prior to travel?
                      </p>
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="haveInfectiousDiseaseHistory1"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="haveInfectiousDiseaseHistory"
                            id="haveInfectiousDiseaseHistory1"
                            value={"yes"}
                            onChange={(e) =>
                              setFieldValue(
                                "haveInfectiousDiseaseHistory",
                                "yes"
                              )
                            }
                            onBlur={handleBlur}
                          />
                          Yes
                        </label>
                      </div>

                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="haveInfectiousDiseaseHistory2"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="haveInfectiousDiseaseHistory"
                            id="haveInfectiousDiseaseHistory2"
                            value={"no"}
                            defaultChecked={true}
                            onChange={(e) =>
                              setFieldValue(
                                "haveInfectiousDiseaseHistory",
                                "no"
                              )
                            }
                            onBlur={handleBlur}
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <p>Have you been sick in the past 30 days?</p>
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="haveYouBeenSick1"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="haveYouBeenSick"
                            id="haveYouBeenSick1"
                            value={"yes"}
                            onChange={() =>
                              setFieldValue("haveYouBeenSick", "yes")
                            }
                            onBlur={handleBlur}
                          />
                          Yes
                        </label>
                      </div>

                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="haveYouBeenSick2"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="haveYouBeenSick"
                            id="haveYouBeenSick2"
                            value={"no"}
                            defaultChecked={true}
                            onChange={() =>
                              setFieldValue("haveYouBeenSick", "no")
                            }
                            onBlur={handleBlur}
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {values?.haveYouBeenSick === "yes" && (
                <div className="row">
                  {symptomFields.map((data, index) => {
                    const { symptoms } = data;
                    return (
                      <>
                        <div className="col-md-6" key={index}>
                          <label>
                            Symptoms<span className="needed">*</span>{" "}
                          </label>
                          <select
                            className="form-control"
                            name="symptoms"
                            value={data.symptoms}
                            onChange={(evnt) =>
                              handleSymptomChanges(index, evnt)
                            }
                            onBlur={handleBlur}
                            aria-required="true"
                          >
                            <option value="">Please Select</option>
                            {symptomsList?.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>

                          <StyledError>{errors.symptoms}</StyledError>
                        </div>
                        {index == symptomFields?.length - 1 ? (
                          <div className="col-md-6 btn-wrapper">
                            <div>
                              <button
                                type="button"
                                className="add-button"
                                onClick={addSymptomField}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        ) : null}

                        <div className="col-md-6 btn-wrapper">
                          {symptomFields?.length !== 1 &&
                          index < symptomFields?.length - 1 ? (
                            <button
                              type="button"
                              className="btn-theme btn"
                              onClick={removeSymptomFields}
                            >
                              Delete
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}

              <div className="form-group btn-fom">
                <button
                  className="btn-theme"
                  type="submit"
                  id="btn_individual_new"
                  name="application_save"
                  value="Submit"
                >
                  CONTINUE FOR THE SUMMARY
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Divwrapper>
  );
};

export default EtravelForm;
