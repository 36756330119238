import styled from "styled-components";

export const StyledPreview = styled.div`
.in-banner {
  position: relative;
}
.in-banner img {
  width: 100%;
  
  @media (max-width: 767px) {
      min-height: 144px;
      object-fit: cover;
  }
  }
  .in-banner h1 {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  margin: 0;
  border-bottom: 2px solid #fff;
  }
  
  .text-ban {
  position: absolute;
  top: 50%;
  left: 19%;
  transform: translateY(-50%);
  
  @media (max-width: 767px){
      left: 0%;
      padding: 0 15px;
  }
  
  @media (max-width: 1440px){
      left: 6%;
  }
  }

.about-us {
padding: 96px 0;
}


  table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 30px;
    table-layout: fixed;
    width: 100%;
    border: 1px solid;
  }

  th {
    // text-align: left;
    // width: 30%;
    // background: #f3f3f3;
    // border: 1px solid;
    // font-weight: bold;

    text-align: center;
    border: 1px solid #000000;
    border-collapse: collapse;
    padding: 5px;
  }

  th,
  td {
    padding: 8px 12px;
    border: 1px solid;
    inline-size: max-content;
  }

  .center-align {
    text-align: center;
  }
  & h4 {
    font-size: 20px;
    color: #d80714;
    font-family: "Montserrat", sans-serif;
    letter-spacing: -0.13px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 15px;
    text-rendering: optimizeLegibility;
  }

  & a {
    color: #00abc9;
    outline: 0;
    text-decoration: none;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }

  #inlineCheckbox1 {
    width: 21px;
    height: 21px;
    position: relative;
    top: 5px;
    outline: 2px solid #d80714;
    outline-offset: -2px;
  }

  & p {
    color: black;
    font-weight: 600;
  }

  label {
    font-weight: bold !important;
    color: #000;
    font-size: 16px;
  }

  .btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
  }
  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .btn-pay {
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: #00abc9;
    color: #fff;
    padding: 17px 40px;
    display: inline-block;
    transition: all ease 0.3s;
    border: none;
  }

  .btn-pay:hover {
    color: #fff;
    background-color: #333;
    border-color: #333;
  }
  .signature-canvas {
    border: 1px solid black;
    touch-action: none;

    @media (max-width: 300px) {
      width: -webkit-fill-available;
    }
  }

  #signature-pad {
    margin-top: 15px;
  }

  // & p {
  //   color: red;
  // }

  .application {
    padding: 90px 0;

    @media (max-width: 767px){
    padding: 41px 0;
}
  }
  & form {
    width: 100%;
    background: #fff;
  
    & label {
      font-weight: bold;
      font-size: 14px;
  
      .needed {
        font-weight: bold;
        font-style: italic;
        color: #d3080c;
      }
  
    }
  
   }
  
   .textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  
    .form-control {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #0042b3;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border-radius: 0;
      box-shadow: none;
      height: 50px !important;
      border-color: #0042b3 !important;
      // background-color: #f5f6f7 !important;
      box-shadow: 0 0 5pt 0.5pt #adc1db !important;
  }
  
  
     
    }
  
    & input {
      // padding: 20px 15px !important;
      }
  
  
      & h3 {
      text-align:left;
      }
  
      .form-group {
        margin-bottom: 15px;
    }
    .btn-fom {
        margin: 38px 0 0;
    }
  
      .btn-theme {
        background-color: #cf0a21;
        border: 1px solid #fff;
    }
  
    .btn-theme {
      background-color: #cf0a21;
      color: #fff;
      border: 1px solid #ffcd00;
      display: inline-block;
  }
  .btn-fom a, .btn-fom button {
    padding: 12px 56px;
    font-size: 18px;
  }
  
`;

export const StyledError = styled.div`
  color: red;
`;

export const StyledPreviewForm = styled.div`
  padding: 55px 260px;

  @media (max-width: 1200px) {
    padding: 55px 120px;
  }

  @media (max-width: 1000px) {
    padding: 50px 90px;
  }

  @media (max-width: 800px) {
    padding: 50px 30px;
  }

  @media (max-width: 400px) {
    padding: 50px 10px;
  }
  & h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 20px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 30px;
    table-layout: fixed;
    width: 100%;
    border: 1px solid;
  }

  th {
    text-align: left;
    width: 30%;
    background: #f3f3f3;
    border: 1px solid;
    font-weight: bold;
  }

  th,
  td {
    padding: 8px 12px;
    border: 1px solid;
    inline-size: max-content;
  }

  .center-align {
    text-align: center;
  }
  & h4 {
    font-size: 20px;
    color: #d80714;
    font-family: "Montserrat", sans-serif;
    letter-spacing: -0.13px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 15px;
    text-rendering: optimizeLegibility;
  }

  & a {
    color: #00abc9;
    outline: 0;
    text-decoration: none;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }

  #inlineCheckbox1 {
    width: 21px;
    height: 21px;
    position: relative;
    top: 5px;
    outline: 2px solid #d80714;
    outline-offset: -2px;
  }

  & p {
    color: black;
    font-weight: 600;
  }

  label {
    font-weight: bold !important;
    color: #000;
    font-size: 16px;
  }

  .btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
  }
  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .btn-pay {
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: #00abc9;
    color: #fff;
    padding: 17px 40px;
    display: inline-block;
    transition: all ease 0.3s;
    border: none;
  }

  .btn-pay:hover {
    color: #fff;
    background-color: #333;
    border-color: #333;
  }
  .signature-canvas {
    border: 1px solid black;
    touch-action: none;

    @media (max-width: 300px) {
      width: -webkit-fill-available;
    }
  }

  #signature-pad {
    margin-top: 15px;
  }

  // & p {
  //   color: red;
  // }
`;
