import styled from "styled-components";

export const StyledSlide = styled.div`

  .caption {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    color: #fff;
    text-align: center;
  }
  @media only screen and (max-width: 767px) .caption {
    width: 550px;
    left: 11%;
  }
  @media only screen and (max-width: 990px) .caption {
    width: 600px;
    left: 11%;
  }

  .display {
    font-weight: 800;
    font-size: 48px;
    text-transform: uppercase;
    margin: 0;

    @media (max-width: 767px) {
      padding: 0 15px;
      font-size: 22px;
    }

    @media (max-width: 1199px) .display {
      font-size: 32px;
    }
  }

  .content {
    font-weight: 600;
    font-size: 20px;
    margin: 31px 0 45px;
    color: #fff;

    @media (max-width: 1199px) {
      font-size: 18px;
      margin: 20px 0 28px;
    }

      @media (max-width: 767px) {
        font-size: 15px;
        margin: 16px 0 28px;
        line-height: 16px;
      }
    
  }

  & a {
    text-decoration: none;
    margin: 0;
    display: inline-block;
    padding: 15px 57px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;

    @media (max-width: 767px) {
      padding: 9px 41px;
      font-size: 16px;
    }
  }

  .btn-theme {
    background-color: #cf0a21;
    border: 1px solid #fff;
  }

  .owl-stage {
    width: 1704px !important;
  }

  @media (max-width: 991px) .d-block {
    display: block;
    width: 100%;
    .owl-carousel .owl-item img {
      display: block;
      width: 100%;
      min-height: 400px;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    @media (max-width: 991px) {
      min-height: 400px;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
`;
