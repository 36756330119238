import styled from "styled-components";

export const Divwrapper = styled.div`
.in-banner {
  position: relative;
}

.flag-dropdown {
    margin-top: -45px;
    border: 1px solid black;
    margin-right: 25rem;
    border: none;
}

// input{
//   padding-left:20px
// }

// .space {
//   padding-left: 10px
//   }


.react-tel-input .flag-dropdown {
  position: absolute;
  top: 50px;
  bottom: 5px;
  padding: 0;
  margin-left: 2px;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 76px 9 0 10px;
  border-radius: 5rem;
}

// .mob-input {
//   text-align: left; 
//   margin-left:5px;

// }

#rfs-btn {
  border:none;
  width: min-content;
}
}
.in-banner img {
  width: 100%;
  
  @media (max-width: 767px) {
      min-height: 144px;
      object-fit: cover;
  }
  }
  .in-banner h1 {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  margin: 0;
  border-bottom: 2px solid #fff;
  }
  
  .text-ban {
  position: absolute;
  top: 50%;
  left: 19%;
  transform: translateY(-50%);
  
  @media (max-width: 767px){
      left: 0%;
      padding: 0 15px;
  }
  
  @media (max-width: 1440px){
      left: 6%;
  }
  }
 & p {
    // color: #fff;
    margin: 13px 00 0;
}
}

.about-us {
  padding: 96px 0;
}

.border-bot {
  position: relative;
  display: none;
}

.border-bot h2 {
  margin: 0 0 70px;
}
.privacy {
  padding: 25px 0;
  line-height: 25px !important;
  text-align: justify;
}

.large {
  color: #0042b3;
  font-weight: bold;
}

& h3 {
  font-size: 20px;
  color: #0042b3;
  font-weight: bold;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

& a {
  color: #337ab7;
  text-decoration: none;
}
& h2 {
  text-align: center;
}

.application {
  padding: 90px 0;
}
& form {
  width: 100%;
  background: #fff;

  & label {
    font-weight: bold;
    font-size: 14px;

    .needed {
      font-weight: bold;
      font-style: italic;
      color: #d3080c;
    }
  }
 }

 .css-13cymwt-control {
  border: none;
}

.css-t3ipsp-control {
  border: none;
  box-shadow:none;
}

 .textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

  .form-control {
    display: block;
    width: 100%;
    // padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #0042b3;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 0;
    box-shadow: none;
    height: 50px !important;
    border-color: #0042b3 !important;
    // background-color: #f5f6f7 !important;
    box-shadow: 0 0 5pt 0.5pt #adc1db !important;
}
  }

  & input {
    // padding: 20px 15px !important;
    }

    & h3 {
    text-align:left;
    }

    .form-group {
      margin-bottom: 15px;
  }
  .btn-fom {
      margin: 38px 0 0;
  }

    .btn-theme {
      background-color: #cf0a21;
      border: 1px solid #fff;
  }

  .btn-theme {
    background-color: #cf0a21;
    color: #fff;
    border: 1px solid #ffcd00;
    display: inline-block;
}
.btn-fom a, .btn-fom button {
  padding: 12px 56px;
  font-size: 18px;
}

.add-button {
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 3px;
    border: none;
    background-color: #0d6efd;
    color: #fff;
}

.btn-wrapper{
  margin-top:30px;
}

`;

export const StyledError = styled.div`
  color: red;
`;

export const StyledTable = styled.div`
  border: 1px solid #f2f5ff;
  background-color: #f2f5ff;
  border-radius: 5px;
  margin-right: 40rem;
  margin-top: 10px;
`;
