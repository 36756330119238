import React, { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StyledError, StyledCaptcha, StyledContact } from "./style";
import { sendContactMail } from "../../redux/paymentSlice";
import { toast } from "react-toastify";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";

const initialValues = {
  name: "",
  email: "",
  orderId: "",
  message: "",
};

const contactUsSchema = yup.object({
  name: yup.string().required("Please enter your name"),
  email: yup.string().required("Please enter your email"),
  orderId: yup.string().required("Please enter your order id"),
  message: yup.string().required("Please enter your message"),
});

const ContactUs = () => {
  const dispatch = useDispatch();
  const orderId = useSelector((state) => state.applyNow.orderIDs);

  useEffect(() => {
    document.title = "Contact";
  }, []);

  const { values, errors, handleBlur, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: initialValues,
      validationSchema: contactUsSchema,
      onSubmit: (values) => {
        // dispatch(sendContactMail(values))
        //   .unwrap()
        //   .then((res) => {
        //     if (res.status === 1) {
        //       resetForm();
        //       toast.success(`${res.message}`, {
        //         className: "toast-message",
        //       });
        //     } else {
        //       toast.error(`${res.message}`, {
        //         className: "toast-message",
        //       });
        //     }
        //   });
      },
    });

  return (
    <StyledContact>
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>Contact Us</h1>
        </div>
      </section>
      <section className="about-us contact-us">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <input
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledError>{errors.name}</StyledError>
              </div>
              <div className="col-md-6 col-xs-12">
                <input
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledError>{errors.email}</StyledError>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <input
                  type="text"
                  placeholder="Order Id"
                  name="orderId"
                  value={values.orderId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledError>{errors.orderId}</StyledError>
              </div>
              <div className="col-md-12 col-xs-12">
                <input
                  type="textarea"
                  placeholder="Message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledError>{errors.message}</StyledError>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <StyledCaptcha>
                  <div className="captcha">
                    <ReCAPTCHA
                      sitekey="6LdF1yQfAAAAAIUKaElIP0x9fmrfDKaafzBfd64i"
                      // SITE KEY : 6LcbzlwgAAAAANeDK8WPE5OgF0M_gs9yjS5RcLSi
                      // SITE SECRET : 6LcbzlwgAAAAADPZs_A4mnAi8tLBDBi-g5x8s_GA
                      style={{
                        transform: "scale(0.85)",
                        transformOrigin: "0 0",
                      }}
                    />
                  </div>
                </StyledCaptcha>
              </div>
              <input type="submit" className="btn" value="Send Message" />
            </div>
          </form>
        </div>
      </section>
    </StyledContact>
  );
};

export default ContactUs;
