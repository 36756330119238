import styled from "styled-components";

export const StyledSection = styled.div`
  .in-banner {
    position: relative;
  }
  .in-banner img {
    width: 100%;

    @media (max-width: 767px) {
      min-height: 144px;
      object-fit: cover;
    }
  }
  .in-banner h1 {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    border-bottom: 2px solid #fff;
  }

  .text-ban {
    position: absolute;
    top: 50%;
    left: 19%;
    transform: translateY(-50%);

    @media (max-width: 767px) {
      left: 0%;
      padding: 0 15px;
    }

    @media (max-width: 1440px) {
      left: 6%;
    }
  }

  .about-us {
    padding: 96px 0;
  }

  .border-bot {
    position: relative;
    display: none;
  }

  .border-bot h2 {
    margin: 0 0 70px;
  }
  .privacy {
    padding: 25px 0;
    line-height: 25px !important;
    text-align: justify;
  }

  .large {
    color: #0042b3;
    font-weight: bold;
  }

  & h3 {
    font-size: 20px;
    color: #0042b3;
    font-weight: bold;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  & a {
    color: #337ab7;
    text-decoration: none;
  }
  & h2 {
    text-align: center;
  }

  .add-table {
    padding: 95px 0;
  }

  .img-container {
    margin: 10px;

    & img {
      width: 128px px;
      height: 128px;
      display: block;
      margin: 0 auto;
      padding-top: 20px;
    }

    & p {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      color: #333333;
      line-height: 24px;
      font-weight: 400;
      text-align: center;
    }
  }

  .img-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.125);
    height: 234px;
    padding-top: 20px;
  }
`;

export const StyledTitleSection = styled.div`
  text-align: center;
  padding-bottom: 30px;

  & h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #2c4964;
  }

    & p {
      margin-bottom: 0;
    }
  }
`;
