import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../../pages/Home";
import ApplyNow from "../../../pages/ApplyNow";
import AdditionalBenefits from "../../../pages/AdditionalBenefits";
import TermsAndConditions from "../../../pages/TermsAndConditions";
import Privacypolicy from "../../../pages/Privacypolicy";
import RefundPolicy from "../../../pages/RefundPolicy";
import ContactUs from "../../../pages/ContactUs";
import PageNotFound from "../../../pages/PageNotFound";
import Country from "../../../pages/Country/Country";
import Preview from "../../../pages/Preview/Preview";
import Payment from "../../../pages/Payment/Payment";
import ThankYou from "../../../pages/ThankYou/index";
import TrackOrder from '../../../pages/TrackOrder/TrackOrder';
import DownloadHistory from '../../../pages/DownloadHistory/DownloadHistory';
import FAQ from '../../../pages/FAQ/index';
import EtravelForm from '../../../pages/EtravelForm/EtravelForm';
import Summary from "../../../pages/Summary";

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/apply-now" element={<ApplyNow />} />
        <Route path="/additional-benefits" element={<AdditionalBenefits />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/countries" element={<Country />} />
        <Route path="/preview" element={<Preview />} />
        <Route path="/payment-net" element={<Payment />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/etravel-form" element={<EtravelForm />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="/track-order/:orderId" element={<TrackOrder />} />
        <Route path="/get-new-order-details/:orderId" element={<DownloadHistory />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
};

export default Main;
