import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applyNowService from "../services/applyNowService";

export const getCountryListing = createAsyncThunk(
  "/getCountryListing",
  async () => {
    try {
      const response = await applyNowService.getCountryListing();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getEmbassyListing = createAsyncThunk(
  "/getEmbassyListing",
  async (countryId) => {
    try {
      const response = await applyNowService.getEmbassyListing(countryId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getCountryInfoListing = createAsyncThunk(
  "/getCountryInfoListing",
  async () => {
    try {
      const response = await applyNowService.getCountryInfoListing();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const customerApplicationForm = createAsyncThunk(
  "/customerApplicationForm",
  async (applyData) => {
    try {
      const response = await applyNowService.customerApplicationForm(applyData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const customerEtaApplicationForm = createAsyncThunk(
  "/customerEtaApplicationForm",
  async (applyData) => {
    try {
      const response = await applyNowService.customerEtaApplicationForm(
        applyData
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const customerEtaApplicationPreview = createAsyncThunk(
  "/customerEtaApplicationPreview",
  async (orderIdData) => {
    try {
      const response = await applyNowService.customerEtaApplicationPreview(
        orderIdData
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const customerApplicationPreview = createAsyncThunk(
  "/customerApplicationPreview",
  async (orderIdData) => {
    try {
      const response = await applyNowService.customerApplicationPreview(
        orderIdData
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteMember = createAsyncThunk(
  "/deletePricingListing",
  async (data) => {
    try {
      await applyNowService.deleteMember(data);
      return data.orderId;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getTotalAmount = createAsyncThunk(
  "/getTotalAmount",
  async (amountData) => {
    try {
      const response = await applyNowService.getTotalAmount(amountData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const applyNowReducer = createSlice({
  name: "applyNow",
  initialState: {
    loading: false,
    error: null,
    showNavbar: false,
    countryList: [],
    embassyList: [],
    countryInfoList: [],
    countryCodeList: [],
    applyNowFormData: [],
    etaFormData: [],
    orderIDs: null,
    applicantDetails: [],
    etaApplicantDetails: [],
    amount: null,
    customerDetails: {},
  },
  reducers: {
    toggleNavbar: (state) => ({
      ...state,
      showNavbar: !state.showNavbar,
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getCountryListing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCountryListing.fulfilled, (state, action) => {
      state.loading = false;
      state.countryList = action?.payload?.data;
    });
    builder.addCase(getCountryListing.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getEmbassyListing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEmbassyListing.fulfilled, (state, action) => {
      state.loading = false;
      state.embassyList = action?.payload?.data;
    });
    builder.addCase(getEmbassyListing.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getCountryInfoListing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCountryInfoListing.fulfilled, (state, action) => {
      state.loading = false;
      state.countryInfoList = action?.payload?.data;
    });
    builder.addCase(getCountryInfoListing.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(customerApplicationForm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customerApplicationForm.fulfilled, (state, action) => {
      state.loading = false;
      state.applyNowFormData = action?.payload?.data;
      state.orderIDs = action?.payload?.Id;
    });
    builder.addCase(customerApplicationForm.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(customerEtaApplicationForm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customerEtaApplicationForm.fulfilled, (state, action) => {
      state.loading = false;
      state.etaFormData = action?.payload?.data;
      // state.orderIDs = action?.payload?.Id;
    });
    builder.addCase(customerEtaApplicationForm.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(customerEtaApplicationPreview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      customerEtaApplicationPreview.fulfilled,
      (state, action) => {
        state.loading = false;
        state.etaApplicantDetails = action?.payload?.data;
      }
    );
    builder.addCase(customerEtaApplicationPreview.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(customerApplicationPreview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customerApplicationPreview.fulfilled, (state, action) => {
      state.loading = false;
      state.applicantDetails = action?.payload?.data;
    });
    builder.addCase(customerApplicationPreview.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteMember.fulfilled, (state, action) => {
      state.loading = false;
      state.applyNowFormData = state.applyNowFormData.filter(
        (item) => item?.id !== action?.payload
      );
    });
    builder.addCase(deleteMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getTotalAmount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTotalAmount.fulfilled, (state, action) => {
      state.loading = false;
      state.amount = action?.payload?.amount;
      state.customerDetails = action?.payload?.data;
    });
    builder.addCase(getTotalAmount.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { toggleNavbar } = applyNowReducer.actions;

export default applyNowReducer.reducer;
