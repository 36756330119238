import React from "react";
import { StyledFooter, StyledParentFooter } from "./style";
import { useLocation } from "react-router-dom";
import AdminLogo from "../../../../assests/img/philippineLogo.png";


const Footer = () => {
  const location = useLocation();
  let path = location.pathname;
  return (
    <div>
      {path == "/" && (
        <StyledFooter>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 footer-contact">
                <img src={AdminLogo} alt="logo" />
              </div>

              <div className="col-lg-4 col-md-4 footer-links">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="additional_benefits.php">Additional Benefits</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="fees.php">Fees</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="faq.php">FAQ</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-4 footer-links">
                <h4>Important Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="terms_condition.php">Term &amp; Condition</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="privacy_policy.php">Privacy Policy</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="refund_policy.php">Refund Policy</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="https://www.k-eta.go.kr/" target="blank">
                      Visit govt site
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </StyledFooter>
      )}
      <StyledParentFooter>
        <div className="container py-12" id="footer">
          <div className="me-md-auto text-center">
            <div className="copyright">
              © 2023{" "}
              <strong>
                <span>philippines-evisa.com</span>
              </strong>
              . All Rights Reserved
            </div>
          </div>
        </div>
      </StyledParentFooter>
    </div>
  );
};

export default Footer;
