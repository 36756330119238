import React, { useEffect } from "react";
import { StyledWrapper } from "./style";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";

const Privacypolicy = () => {
  useEffect(() => {
    document.title = "Privacy Policy";
  }, []);
  return (
    <StyledWrapper>
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>Privacy Policy</h1>
        </div>
      </section>

      <section className="privacy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-bot">
                <h2 className="heading">Privacy Policy</h2>
              </div>
              <div className="">
                <p style={{ "text-align": "justify" }}>
                  This privacy policy sets out how philippines-evisa.com uses
                  and protects any information that you give
                  philippines-evisa.com when you use this website.
                  philippines-evisa.com is committed to ensuring that your
                  privacy is protected. Should we ask you to provide certain
                  information by which you can be identified when using this
                  website, then you can be assured that it will only be used in
                  accordance with this privacy statement. philippines-evisa.com
                  may change this policy from time to time by updating this
                  page. You should check this page from time to time to ensure
                  that you are happy with any changes. This policy is effective
                  from 1st June 2015.
                </p>
                <h3 className="large"> WHAT WE COLLECT </h3>
                We may collect the following information: Name Contact
                Information including Email Address Demographic Information such
                as Zip Code Passport Number
                <h3 className="large">
                  {" "}
                  WHAT WE DO WITH THE INFORMATION WE GATHER{" "}
                </h3>
                We require this information to meet your needs and fulfill your
                order
                <h3 className="large"> SECURITY </h3>
                We are committed to ensuring that your information is secure. In
                order to prevent unauthorized access or disclosure, we have put
                in place suitable physical, electronic and managerial procedures
                to safeguard and secure the information we collect online. Your
                payment details are collected via the secure servers at , and at
                no time does any other organisation (including ours) have access
                to your payment details.
                <h3 className="large"> HOW WE USE COOKIES </h3>A cookie is a
                small file which asks permission to be placed on your computer's
                hard drive. Once you agree, the file is added and the cookie
                helps analyse web traffic or lets you know when you visit a
                particular site. Cookies allow web applications to respond to
                you as an individual. The web application can tailor its
                operations to your needs, likes and dislikes by gathering and
                remembering information about your preferences. We use traffic
                log cookies to identify which pages are being used. This helps
                us analyse data about webpage traffic and improve our website in
                order to tailor it to customer needs. We only use this
                information for statistical analysis purposes and then the data
                is removed from the system. Overall, cookies help us provide you
                with a better website by enabling us to monitor which pages you
                find useful and which you do not. A cookie in no way gives us
                access to your computer or any information about you, other than
                the data you choose to share with us. You can choose to accept
                or decline cookies. Most web browsers automatically accept
                cookies, but you can usually modify your browser setting to
                decline cookies if you prefer. This may prevent you from taking
                full advantage of the website.
                <h3 className="large"> LINKS TO OTHER WEBSITES </h3>
                Our website may contain links to other websites of interest.
                However, once you have used these links to leave our site, you
                should note that we do not have any control over that other
                website. Therefore, we cannot be responsible for the protection
                and privacy of any information which you provide whilst visiting
                such sites and such sites are not governed by this privacy
                statement. You should exercise caution and look at the privacy
                statement applicable to the website in question.
                <h3 className="large">
                  {" "}
                  CONTROLLING YOUR PERSONAL INFORMATION{" "}
                </h3>
                We will not sell, distribute or lease your personal information
                to third parties unless we are required by law to do so. You
                may, at any time, request that any of your data that we have
                collected as a result of you placing an order with us be removed
                from our systems. To do this, simply send an email to{" "}
                <a href="mailto:inquiries@philippines-evisa.com">
                  inquiries@philippines-evisa.com
                </a>{" "}
                stating your full name and instructing us to remove all
                personally identifiable data from our systems. Your data will be
                removed within 24 hours.
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StyledWrapper>
  );
};

export default Privacypolicy;
