import React, { useEffect } from "react";
import { StyledPreview } from "./style";
import Image from "../../assests/img/Sigiriya-Sri-Lanka.jpg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { decryptVal } from "../../utility/utility";
import { customerEtaApplicationPreview } from "../../redux/applyNowSlice";
import moment from "moment";

const Summary = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const etaApplicantDetails = useSelector(
    (state) => state.applyNow.etaApplicantDetails
  );

  let Id = decryptVal(localStorage.getItem("Id"));

  useEffect(() => {
    if (typeof Id !== undefined) {
      dispatch(customerEtaApplicationPreview({ Id: Id }))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
          } else {
            toast.error(`${res.message}`, {
              className: "toast-message",
            });
          }
        });
    }
    document.title = "Review Application";
  }, [dispatch, Id]);

  return (
    <StyledPreview>
      <section className="in-banner">
        <img src={Image} alt="" />
        <div className="text-ban">
          <h1>Summary</h1>
          <p>Please confirm and submit your details.</p>
        </div>
      </section>
      <section className="application">
        <div className="container">
          <table>
            {etaApplicantDetails?.map((item, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <th colSpan="2">Order Details</th>
                  </tr>
                  <tr>
                    <th colSpan="2">
                      Order ID : {`${item?.prefix}${item?.id}`}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan="2">Application</th>
                  </tr>
                  <tr>
                    <th colSpan="2">Personal Profile</th>
                  </tr>

                  {item?.first_name && (
                    <tr>
                      <th>First Name</th>
                      <td>{item?.first_name}</td>
                    </tr>
                  )}

                  {item?.middle_name && (
                    <tr>
                      <th>Middle Name</th>
                      <td>{item?.middle_name}</td>
                    </tr>
                  )}

                  {item?.last_name && (
                    <tr>
                      <th>Last Name</th>
                      <td>{item?.last_name}</td>
                    </tr>
                  )}

                  {item?.suffix && (
                    <tr>
                      <th>Suffix</th>
                      <td>{item?.suffix}</td>
                    </tr>
                  )}

                  {item?.passport_number && (
                    <tr>
                      <th>Passport Number</th>
                      <td>{item?.passport_number}</td>
                    </tr>
                  )}

                  {item?.sex && (
                    <tr>
                      <th>Sex</th>
                      <td>{item?.sex}</td>
                    </tr>
                  )}

                  {item?.date_of_birth && (
                    <tr>
                      <th>Date of Birth</th>
                      <td>
                        {moment(item?.date_of_birth).format("MM/DD/YYYY")}
                      </td>
                    </tr>
                  )}

                  {item?.country_of_birth && (
                    <tr>
                      <th>Country of Birth</th>
                      <td>{item?.country_of_birth}</td>
                    </tr>
                  )}

                  <tr>
                    <th>Occupation</th>
                    <td>{item?.occupation ? item?.occupation : "N/A"}</td>
                  </tr>

                  {item?.citizenship && (
                    <tr>
                      <th>Citizenship</th>
                      <td>{item?.citizenship}</td>
                    </tr>
                  )}

                  {item?.mobile_number && (
                    <tr>
                      <th>Mobile Number</th>
                      <td>{item?.mobile_number}</td>
                    </tr>
                  )}

                  <tr>
                    <th colSpan="2">Permanent Country of Residence</th>
                  </tr>

                  {item?.country && (
                    <tr>
                      <th>Country</th>
                      <td>{item?.country}</td>
                    </tr>
                  )}

                  {item?.region && (
                    <tr>
                      <th>Region</th>
                      <td>{item?.region}</td>
                    </tr>
                  )}

                  {item?.state && (
                    <tr>
                      <th>Province</th>
                      <td>{item?.state}</td>
                    </tr>
                  )}

                  {item?.city && (
                    <tr>
                      <th>Municipality</th>
                      <td>{item?.city}</td>
                    </tr>
                  )}

                  {item?.barangay && (
                    <tr>
                      <th>Barangay</th>
                      <td>{item?.barangay}</td>
                    </tr>
                  )}

                  {item?.house_number && (
                    <tr>
                      <th>House No./Bldg./Street</th>
                      <td>{item?.house_number}</td>
                    </tr>
                  )}

                  {item?.address && (
                    <tr>
                      <th>Address Line 2</th>
                      <td>{item?.address}</td>
                    </tr>
                  )}

                  <tr>
                    <th colSpan="2">
                      Travel Details -{" "}
                      {item?.travel_type === "arrival"
                        ? "Philippine Arrival"
                        : item?.travel_type === "departure"
                        ? "DEPARTURE"
                        : null}{" "}
                      via
                      {item?.transportation_type === "air"
                        ? "(AIR)"
                        : item?.transportation_type === "sea"
                        ? "(SEA)"
                        : null}
                    </th>
                  </tr>

                  {item?.travel_arrival_date && (
                    <tr>
                      <th>Date Of Arrival</th>
                      <td>
                        {moment(item?.travel_arrival_date).format(
                          "MMMM/DD/YYYY"
                        )}
                      </td>
                    </tr>
                  )}

                  {item?.country_of_origin && (
                    <tr>
                      <th>Country of Origin</th>
                      <td>{item?.country_of_origin}</td>
                    </tr>
                  )}

                  {item?.travel_departure_date && (
                    <tr>
                      <th>Date Of Departure</th>
                      <td>
                        {moment(item?.travel_departure_date).format(
                          "MMMM/DD/YYYY"
                        )}
                      </td>
                    </tr>
                  )}

                  {item?.country_of_destination && (
                    <tr>
                      <th>Country Of Destination</th>
                      <td>{item?.country_of_destination}</td>
                    </tr>
                  )}

                  {item?.destination_address && (
                    <tr>
                      <th>Destination Address</th>
                      <td>{item?.destination_address}</td>
                    </tr>
                  )}

                  {item?.are_you_on_overseas_filipino_worker == "yes" && (
                    <tr>
                      <th>Are you an Overseas Filipino Worker(OFW)?</th>
                      <td>{item?.are_you_on_overseas_filipino_worker}</td>
                    </tr>
                  )}

                  <tr>
                    <th>OFW Classification</th>
                    <td>
                      {item?.are_you_on_overseas_filipino_worker === "yes"
                        ? item?.ofw_classification
                        : "N/A"}
                    </td>
                  </tr>

                  {item?.purpose_of_travel && (
                    <tr>
                      <th>Purpose of Travel</th>
                      <td>{item?.purpose_of_travel}</td>
                    </tr>
                  )}

                  {item?.traveller_type && (
                    <tr>
                      <th>Traveller Type</th>
                      <td>{item?.traveller_type}</td>
                    </tr>
                  )}

                  {item?.voyage_number && (
                    <tr>
                      <th>Voyage Number</th>
                      <td>{item?.voyage_number}</td>
                    </tr>
                  )}

                  {item?.vessel_name && (
                    <tr>
                      <th>Vessel Name</th>
                      <td>{item?.vessel_name}</td>
                    </tr>
                  )}

                  {item?.flight_number && (
                    <tr>
                      <th>Flight Number</th>
                      <td>{item?.flight_number}</td>
                    </tr>
                  )}

                  {item?.airline_name && (
                    <tr>
                      <th>Name of Airline</th>
                      <td>{item?.airline_name}</td>
                    </tr>
                  )}

                  {item?.airport_of_arrival && (
                    <tr>
                      <th>Airport Of Arrival</th>
                      <td>{item?.airport_of_arrival}</td>
                    </tr>
                  )}

                  {item?.airport_of_departure && (
                    <tr>
                      <th>Airport Of Departure</th>
                      <td>{item?.airport_of_departure}</td>
                    </tr>
                  )}

                  {item?.sea_port && (
                    <tr>
                      <th>Seaport Of Departure</th>
                      <td>{item?.sea_port}</td>
                    </tr>
                  )}

                  {item?.cabin && (
                    <tr>
                      <th>Seat/Bed Number</th>
                      <td>{item?.cabin}</td>
                    </tr>
                  )}

                  {item?.travel_type === "arrival" && (
                    <>
                      <tr>
                        <th colSpan="2">
                          Destination Upon Arrival in the Philippines
                        </th>
                      </tr>

                      {item?.destination_type && (
                        <tr>
                          <th>Type</th>
                          <td>{item?.destination_type}</td>
                        </tr>
                      )}

                      {item?.specification && (
                        <tr>
                          <th>Specification</th>
                          <td>{item?.specification}</td>
                        </tr>
                      )}

                      {item?.region && (
                        <tr>
                          <th>Region</th>
                          <td>{item?.region}</td>
                        </tr>
                      )}

                      {item?.tourist_destination && (
                        <tr>
                          <th>Hotel/Resort/Tourist Destination</th>
                          <td>{item?.tourist_destination}</td>
                        </tr>
                      )}

                      {item?.municipality && (
                        <tr>
                          <th>Municipality/City</th>
                          <td>{item?.municipality}</td>
                        </tr>
                      )}

                      {item?.street && (
                        <tr>
                          <th>House No./Bldg/Street</th>
                          <td>{item?.street}</td>
                        </tr>
                      )}

                      {item?.contact_number && (
                        <tr>
                          <th>Contact Number</th>
                          <td>{item?.contact_number}</td>
                        </tr>
                      )}

                      {item?.email_address && (
                        <tr>
                          <th>Email Address</th>
                          <td>{item?.email_address}</td>
                        </tr>
                      )}

                      {item?.arrival_contact && (
                        <tr>
                          <th>Contact information</th>
                          <td>{item?.arrival_contact}</td>
                        </tr>
                      )}

                      <tr>
                        <th colSpan="2">Health Declaration</th>
                      </tr>

                      <p>
                        Country(ies) worked, visited and transited in the last
                        30 days (optional)
                      </p>

                      {item?.arrival_country && (
                        <tr>
                          <th>Country</th>
                          <td>{item?.arrival_country}</td>
                        </tr>
                      )}

                      {item?.have_infectious_disease_history && (
                        <tr>
                          <th>
                            Have you had any history of exposure to a person who
                            is sick or known to have communicable/infectious
                            disease in the past 30 days prior to travel?
                          </th>
                          <td>{item?.have_infectious_disease_history}</td>
                        </tr>
                      )}

                      {item?.have_you_been_sick && (
                        <tr>
                          <th>Have you been sick in the past 30 days?</th>
                          <td>{item?.have_you_been_sick}</td>
                        </tr>
                      )}

                      <tr>
                        <th>Symptoms</th>
                        <td> {item?.symptoms ? item?.symptoms : "N/A"}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              );
            })}
          </table>
          <br />
        </div>
      </section>
    </StyledPreview>
  );
};

export default Summary;
