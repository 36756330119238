import styled from "styled-components";

export const StyledWrapper = styled.div`
  .in-banner {
    position: relative;
  }
  .in-banner img {
    width: 100%;

    @media (max-width: 767px) {
      min-height: 144px;
      object-fit: cover;
    }
  }
  .in-banner h1 {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    border-bottom: 2px solid #fff;
  }

  .text-ban {
    position: absolute;
    top: 50%;
    left: 19%;
    transform: translateY(-50%);

    @media (max-width: 767px) {
      left: 0%;
      padding: 0 15px;
    }

    @media (max-width: 1440px) {
      left: 6%;
    }
  }

  .about-us {
    padding: 96px 0;
  }

  .border-bot {
    position: relative;
    display: none;
  }

  .border-bot h2 {
    margin: 0 0 70px;
  }
  .privacy {
    padding: 25px 0;
    line-height: 25px !important;
    text-align: justify;
  }

  .large {
    color: red;
    font-weight: bold;
  }

  & h3 {
    font-size: 20px;
    color: red;
    font-weight: bold;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  & a {
    color: #337ab7;
    text-decoration: none;
  }
  & h2 {
    text-align: center;
  }
`;

export const StyledDiv = styled.div`
  padding: 2rem 15rem;

  & h4 {
    font-size: 1.428em;
  }

  & p {
    text-align: justify;
    color: #000;
    font-size: 16px !important;
    font-family: arial !important;
    font-weight: normal !important;
    margin: 0 0 10px !important;
  }

  & h6 {
    font-weight: bold;
  }
  & h5 {
    font-size: 20px;
    padding-top: 2rem;
    font-weight: bold;
  }

  & li {
    list-style: none;
  }

  & a {
    color: #00abc9;
    text-decoration: none;
  }

  @media (max-width: 1400px) {
    padding: 2rem 5rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 308px) {
    padding: 2rem 3.5rem;
  }

  @media (max-width: 233px) {
    padding: 2rem 2rem;
  }
`;
