import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCountryListing } from "../../redux/applyNowSlice";
import { StyledContainer } from "./style";

const Country = () => {
  const countryList = useSelector((state) => state.applyNow.countryList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountryListing());
    document.title = "Countries";
  }, [dispatch]);

  return (
    <StyledContainer>
      <h2>Countries</h2>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="blue-heading">
              The following countries are eligible for the turkey e-visa
              application
            </div>
            <div className="col-md-3 country-link">
              {countryList?.map((item, index) => {
                return (
                  <>
                    <Link to="">{item?.countryname}</Link>
                    <br />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Country;
