import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrdersData,
  getAllFolderCount,
  getOrdersList,
} from "../../../redux/orderSlice";
import { toast } from "react-toastify";
import Modal from "../../../Components/Modal/index";
import moment from "moment";

const SaveAndContinue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SaveAndContinueList = useSelector((state) => state?.order?.ordersList);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [show, setShow] = useState(false);
  const SelectedData = useSelector((state) => state?.order?.selectedData);
  const [selectedData, setSelectedData] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData([orderId]);
  };

  useEffect(() => {
    if (SelectedData && SelectedData?.length !== 0) {
      setSelectedData(SelectedData);
    }
  }, [SelectedData]);

  const deleteOrder = () => {
    let data = {
      orderId: selectedData,
      updatingStatus: "Deleted",
    };
    dispatch(deleteOrdersData(data))
      .then((res) => {
        handleClose();
        if (res.payload) {
          toast.success(`Order has been deleted successfully`, {
            className: "toast-message",
          });
          dispatch(
            getOrdersList({
              orderName: "savecontinueorder",
              page: page,
              perPage: limit,
            })
          );
          dispatch(getAllFolderCount());
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          toast.success(`${err.message}`, {
            className: "toast-message",
          });
        }
      });
  };

  useEffect(() => {
    dispatch(
      getOrdersList({
        orderName: "savecontinueorder",
        page: page,
        perPage: limit,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/");
        }
      });
    document.title = "Save & Continue | Philippine E-Visa";
  }, [dispatch, navigate, page, limit]);

  const tableData = SaveAndContinueList;
  const twentyEightDaysBack = moment().subtract(28, "d").format("YYYY-MM-DD");
  const columns = [
    {
      name: "Order Id",
      width: "10rem",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      width: "15rem",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      width: "15rem",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Date",
      width: "10rem",
      selector: (row) =>
        row?.date ? moment(new Date(row.date)).format("YYYY-MM-DD") : null,
      sortable: true,
    },
    {
      name: "Status",
      width: "10rem",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      width: "20rem",
      selector: (row) =>
        tableData?.length &&
        moment(new Date(row.date)).format("YYYY-MM-DD") <
          twentyEightDaysBack ? (
          <span>
            <Link
              to="#"
              onClick={() => handleShow({ id: row?.id, status: row?.status })}
              style={{
                textDecoration: "none",
                border: "1px solid #e92a11",
                borderRadius: "5px",
                padding: " 0 5px",
                minWidth: "80px",
                display: "inline-block",
                textAlign: "center",
                height: "40px",
                lineHeight: "40px",
                color: " #e92a11",
                margin: "5px",
              }}
            >
              Delete
            </Link>
          </span>
        ) : (
          " "
        ),
    },
  ];

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Save & Continue</h1>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              customColumns={columns}
              customOrdersData={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="savecontinueorder"
              selectableRowDisabled={(row) =>
                moment(new Date(row.date)).format("YYYY-MM-DD") >
                moment().subtract(28, "d").format("YYYY-MM-DD")
              }
            />
          </div>
        </StyledOrderBlock>
        <Modal
          show={show}
          close={handleClose}
          size={"s"}
          confirmAlert={true}
          noEvent={() => handleClose()}
          yesEvent={() => deleteOrder()}
        ></Modal>
      </form>
    </StyledContainer>
  );
};

export default SaveAndContinue;
