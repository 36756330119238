import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StyledDownload } from "./style";
import {
  downloadPassportDocPdf,
  getDownloadHistory,
} from "../../redux/frontSlice";
import { decryptVal } from "../../utility/utility";

const DownloadHistory = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const downloadFilePath = useSelector(
    (state) => state?.front?.downloadFilePath
  );
  const mainResult = useSelector((state) => state?.front?.mainResult);

  const downloadHistoryList = useSelector(
    (state) => state?.front?.downloadHistoryList
  );

  useEffect(() => {
    let data = { orderId };
    dispatch(getDownloadHistory(data));
  }, []);

  const handleDownloadList = (item) => {
    dispatch(
      downloadPassportDocPdf({
        orderId: decryptVal(orderId),
        customername: item,
        file1: downloadFilePath?.file1,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          let data = { orderId };
          dispatch(getDownloadHistory(data));
        }
      });
  };

  return (
    <StyledDownload>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>
              <b>
                Your application for an e-Visa has been completed successfully.
              </b>
            </p>
          </div>

          <div class="col-md-12">
            <p>
              <b>
                Thank you for using our services for your e-Visa application.
                Your payment has been approved and your e-Visa has been
                processed.
              </b>
            </p>
            <form class="form-inline">
              <p>
                You can download and save your e-Visa by clicking the button
                below.
                <br />
              </p>
              <div class="wrapper" style={{ textAlign: "center" }}>
                {mainResult?.map((item) => {
                  let customerName = item?.name + " " + item?.surname;
                  return (
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visa-docs/${downloadFilePath?.file1}`}
                      target="_blank"
                      download
                      style={{ display: "block" }}
                    >
                      <button
                        type="button"
                        name="doc1"
                        class="btn btn-primary mb-2"
                        id="download_btn"
                        onClick={() => handleDownloadList(customerName)}
                      >
                        {customerName?.toUpperCase()} - Download{" "}
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </a>
                  );
                })}
                <br />
                <br />
              </div>
            </form>
          </div>
          <div class="col-md-12 Responsive-table">
            <br />
            <h3>Downloaded History</h3>

            <table class="table table-striped table-bordered myTable showhistory">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Order ID</th>
                  <th>Customer Name</th>
                  <th>Date &amp; Time (EST)</th>
                  <th>Device IP Address</th>
                  <th>Device</th>
                  <th>Device Type</th>
                  <th>Browser</th>
                  <th>OS</th>
                  <th>Device Location</th>
                </tr>
              </thead>
              {downloadHistoryList?.map((item, index) => {
                return (
                  <tbody>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.order_id}</td>
                      <td>{item?.customername}</td>
                      <td>{item?.create_ts}</td>
                      <td>{item?.ip}</td>
                      <td>{item?.browser.split("-")?.[2]}</td>
                      <td>
                        {`${item?.browser.split("-")?.[2]} ${
                          item?.browser.split("-")?.[3]
                        }`}
                      </td>
                      <td>{item?.browser.split("-")?.[0]}</td>
                      <td>{item?.os}</td>
                      <td>{item?.timezone}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </StyledDownload>
  );
};

export default DownloadHistory;
