import styled from "styled-components";
// import AdminLogo from "../../assests/img/vietnamLogo.jpg";

export const StyledLogin = styled.div`
  

  .submit-btn {
    background-color: #2dbcba;
    color: #fff;
  }
`;
