import React, { useState } from "react";
import { Link } from "react-router-dom";
import { StyledHomeFooter, StyledNewFooter } from "./style";

const HomeFooter = () => {
  const [closeFooter, setCloseFooter] = useState(true);

  return closeFooter == true ? (
    <StyledHomeFooter>
      <div className="cookie-disclaimer" id="cookie-disclaimer">
        <p>
          We use cookies to ensure you get the best experience on our website.
          If you continue browsing, you accept our cookies. If you continue
          browsing, you accept our cookies.
          <Link to="/privacy-policy"> Learnmore </Link>
          <button
            type="button"
            onClick={() => setCloseFooter(false)}
            className="btn cookie-close-btn btn-theme"
            value="close"
          >
            close
          </button>
        </p>
      </div>
    </StyledHomeFooter>
  ) : (
    <StyledNewFooter>
      <div className="container py-12" id="footer">
        <div className="me-md-auto text-center">
          <div>
            Our service is not connected to or affiliated with the South Korea
            Government
          </div>
        </div>
      </div>
    </StyledNewFooter>
  );
};

export default HomeFooter;
