import styled from "styled-components";

export const StyledContainer = styled.div`
  // padding: 100px 0px;
  // background-color: #f7f7f7;

  & h2 {
    text-align: center;
  }

  .in-banner {
    position: relative;
  }
  .in-banner img {
    width: 100%;
  }
  .in-banner h1 {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    border-bottom: 2px solid #fff;
  }

  .text-ban {
    position: absolute;
    top: 50%;
    left: 19%;
    transform: translateY(-50%);
  }

  .faq {
    padding: 90px 0;
  }

  .border-bot {
    position: relative;
    display: none;
  }

  .border-bot h2 {
    margin: 0 0 70px;
  }

  .faq h2 {
    // margin: 0 0 24px;
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #333333;
    line-height: 24px;
    font-weight: 400;
  }
`;

export const StyledChild = styled.div`
  margin: 35px 0 0;
  @media (max-width: 1000px) {
    padding: 2rem 8rem;
  }

  @media (max-width: 665px) {
    padding: 2rem 6rem;
  }

  @media (max-width: 400px) {
    padding: 2rem 3rem;
  }

  @media (max-width: 251px) {
    padding: 2rem 1rem;
  }
`;

export const StyledDiv = styled.div`
  & h5 {
    font-size: 18px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    color: #343a40;
    font-family: dm sans, sans-serif;
    font-weight: 700;
  }

  & p {
    font-size: 16px;
    line-height: 26px;
    padding: 0;
    margin: 0;
    color: #000;
    font-family: "DM Sans", sans-serif;
    text-align: justify;
  }

  headerTitle {
    font-size: 18px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    color: #343a40;
    font-family: dm sans, sans-serif;
    font-weight: 700;
  }
  .accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
  }
  .accordion-button:focus {
    border-color: none;
    outline: none;
    box-shadow: none;
  }
  .accordion-header {
    margin-bottom: 0px;
    margin-top: 15px;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 0;
  }

  .accordion-body {
    padding: var(--bs-accordion-body-padding-y)
      var(--bs-accordion-body-padding-x);
    background-color: #fdf4f5;
    border: 1px solid #d9d9d9;
  }

  .accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    margin: 0 0 10px;
    border: none;
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "+";
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
  }

  .accordion-button:not(.collapsed)::after {
    // background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
  }



  .accordion-button:not(.collapsed){
  background-color: red;
  color: #fff;
  }
`;
