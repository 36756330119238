import Main from "./Main";
import Header from "./Header/index";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/index";
import { useLocation } from "react-router-dom";
import HomeFooter from "./HomeFooter";

const DefaultLayout = (props) => {
  const location = useLocation();

  if (location?.pathname !== null) {
    let pathname =
      location?.pathname !== "/preview" &&
      location?.pathname !== "/payment-net" &&
      location?.pathname !== "/thankyou"
        ? localStorage.removeItem("orderId")
        : null;
  }

  return (
    <div>
      <Header {...props} />
      <Navbar />
      <Main {...props} />
      {location.pathname === "/" ? (
        <>
          <Footer {...props} />
          <HomeFooter />
        </>
      ) : (
        <Footer {...props} />
      )}
    </div>
  );
};

export default DefaultLayout;
